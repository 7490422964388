
export class WebApplicationState {
    auth= undefined;
    clientVersionNumber=undefined;
    libraryVersionNumber=undefined;
    resources = undefined;
    application = undefined;
    browser = undefined;
    canvas = undefined;
    account = undefined;
    simulation = undefined;
    server = undefined;
    author = undefined;
    audio = undefined;
    externalModules = undefined;
    internalModules = undefined;
    sceneModules = undefined;
  
    saveState() {
      this.auth.saveState();    
      this.simulation.saveState();
      this.server.saveState();
      this.browser.saveState();
      this.account.saveState();
      this.application.saveState();
      this.author.saveState();
      this.audio.saveState();
      this.externalModules?.saveState();
      this.internalModules?.saveState();
      this.sceneModules.saveState();
    }
  
    // shutdownState() {
	  //   this.auth?.shutdown()
    //   this.simulation?.shutdown();
    //   this.simulation = undefined;
    //   this.server?.shutdown();
    //   this.server = undefined;
    //   this.browser?.shutdown();
    //   this.browser = undefined;
    //   this.account?.shutdown();
    //   this.account = undefined;
    //   this.application?.shutdown();
    //   this.application = undefined;
    //   this.author?.shutdown();
    //   this.author = undefined;
    //   this.audio?.shutdown();
    //   this.audio = undefined;
    //   this.externalModules?.shutdown();
    //   this.externalModules = undefined;
    //   this.internalModules?.shutdown();
    //   this.internalModules = undefined;
    //   this.sceneModules?.shutdown();
    //   this.sceneModules = undefined;
    // }
  }