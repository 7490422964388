//import { Mp3AudioElement } from "../audio_visual/audio/webAudio";
//import { ResourceCanvasElement } from '../../ResourceCanvasElement';
//import { FileResource } from "../../../resources/FileResource";

export class AudioResource /*extends FileResource*/ {
  resource_element;
  resource_request;
  loading_promise;
  loading_promises = [];
  audio_element;
  checkForErrors() {
    return false;
  }
  // createCanvasElement(vis){
  //     return new ResourceCanvasElement(vis, this);
  // }

  toSourceURLName() {
    return this.resource_request.name;
  }
  constructor(resource_request) {
    //super();
    this.resource_request = resource_request;

    //let url = this.resource_request.toUrlPath();

    this.resource_element = new Audio(/*url*/); //document.createElement("audio");
    this.resource_request.application.memory_diagnostics.start_download("audio", this.resource_request, this.resource_element);
    this.resource_element.autoplay = false;
    //this.resource_element.muted = false;
    this.resource_element.loop = false;
    //this.resource_element.play();
    // this.resource_element.oncanplay= () => {
    //  let q=2;
    // };
    // this.resource_element.onerror= () => {
    //   let q=2;
    // };
    // this.resource_element.playsinline = true;
    // this.resource_element.playsInline = true;

    //this.resource_element.controls = false;
    this.resource_element.name = this.resource_request.name;
    let self = this;

    //this.resource_element.src = url;
    this.loading_promise = new Promise((resolve, reject) => {
      self.resource_element.addEventListener("canplay", function () {
        self.loading_promise = undefined;
        self.loading_promises[0] = undefined;
        resolve(self);
      });

      self.resource_element.addEventListener("error", function () {
        self.loading_promise = undefined;
        self.loading_promises[0] = undefined;
        reject(self);
      });

      // this.resource_element.load();
    });

    this.loading_promise = this.loading_promise.catch((e) => {
      console.log("audio blocked");
    });

    this.loading_promises.push(this.loading_promise);

    resource_request.server_file_cache.loadFromCacheOrUrl(this.resource_element, this.resource_request.toUrlPath());
    this.resource_element.load();
    console.log(`audio:resource:open AudioResource ${this.resource_request.toUrlPath()}`);

  }

  initialize(scene = undefined) {

  }

  dispose() {
    var isLoading = this.isLoading();
    if (isLoading) {
      var self = this;
      var and_then = (res) => {
        self.dispose();
      };
      this.addLoadingThen(and_then);
    } else {

      var url = new URL(this.resource_element.src);
      this.resource_element.src = "";

      console.log(`audio:resource:close AudioResource ${url.pathname}`);
    }
  }

  addLoadingThen(and_then) {
    var latest = this.getLoadingPromise();
    var index;
    index = this.loading_promises.length;
    var next = latest.then(and_then);
    next = next.catch((e) => {
      console.error(e);
    });
    next = next.finally(() => {
      this.loading_promises[index] = undefined;
    });

    this.loading_promises.push(next);
  }

  isLoading() {
    return this.getLoadingPromise() !== undefined;

    // return this.loading_promises.length == 0
    //   ? false
    //   : this.loading_promises[this.loading_promises.length - 1] !== undefined;

    // var i = this.loading_promises.length - 1;
    // //for ( i >= 0; i--) {
    // var each = this.loading_promises[i];
    // if (each !== undefined) {
    //   return true;
    // }
    // //}
    // return false;

    //return this.loading_promise != undefined;
  }

  getLoadingPromise() {
    return this.loading_promises.length == 0 ? undefined : this.loading_promises[this.loading_promises.length - 1];

    // for (var i = this.loading_promises.length - 1; i >= 0; i--) {
    //   var each = this.loading_promises[i];
    //   if (each !== undefined) {
    //     return each;
    //   }
    // }
    // return undefined;

    // return this.loading_promise;
  }

  // getAudioElement() {
  //   if (this.audio_element == undefined) {
  //     this.audio_element = new Mp3AudioElement(this, this.resource_element);
  //   }
  //   return this.audio_element;
  // }
}
