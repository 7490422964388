import { AudioAmbianceSet } from "../audio_visual/audio/audioAmbianceSet.js";
import { Interactive } from "./Interactive.js";
import { Scene } from "./scene.js";
import Graph from "graphology";
import { SceneObject } from "./scene_objects.js";
import { ScenePath } from "./ScenePath.js";
import { InteractiveLayer, InteractiveLayerJson } from "./InteractiveLayer.js";
import { Resources } from '../resources.js';
import { SceneGraphAudioVisualStateSet } from './SceneGraphAudioVisualStateSet.js';
import { SceneGraphAudioVisualScripts } from './SceneGraphAudioVisualScripts.js';

export class SceneGraph {
  simulation; 
  path;
  json;
  graph;
  sceneItemsToPush = [];
  parentSceneGraph;
  sceneGraphIndex;
  audioAmbiance;
  audio_visual_state_set;
  audio_visual_scripts;
  active_audio_visual_script;

  constructor(simulation, json, parentSceneGraph = undefined, path = undefined, sceneGraphIndex = undefined) {
    this.simulation = simulation;
    this.parentSceneGraph = parentSceneGraph;
    this.json = json;
    this.path = path;
    this.sceneGraphIndex = sceneGraphIndex;
    this.graph = new Graph();
    this.audioAmbiance = new AudioAmbianceSet(this.name, simulation.audio);

    if (this.parentSceneGraph) {
      this.pushSceneItems(this.parentSceneGraph.sceneItemsToPush);
    }
  }

  initializeFromJson() {

    if (!this.json.resourcePath) {
      this.json.resourcePath = this.name;
    }

    for (const key in this.json) {
      var value = this.json[key];
      this.initializeGraphFromJsonKey(key, value);
    }

    this.json.sceneCommon?.interactiveLayers?.forEach((element) => {
      this.sceneItemsToPush.push((scene) => new InteractiveLayerJson(element));
    });

    // this.json.sceneCommon?.interactives?.forEach((element) => {
    //   this.sceneItemsToPush.push((scene) => new Interactive(element, scene));
    // });
    // this.json.sceneCommon?.sceneObjects?.forEach((element) => {
    //   this.sceneItemsToPush.push((scene) => new SceneObject(element));
    // });

    this.pushSceneItems(this.sceneItemsToPush);

    for (const { node, attributes } of this.graph.nodeEntries()) {
      if (attributes.scene.audioAmbiance) {
        this.audioAmbiance.addAmbiance(attributes.scene.audioAmbiance);
      }
    }
  }

  get resources() {
    return this.simulation.resources;
  }
  get icanvas() {
    return this.simulation.icanvas;
  }
  get sceneGraphSet() {
    return this.simulation.sceneGraphSet;
  }

  get account() {
    return this.simulation.account;
  }
  get application() {
    return this.simulation.application;
  }

  collectScenes(list) {
    for (const { node, attributes } of this.graph.nodeEntries()) {
      list.push(attributes.scene);
    }
  }

  pushSceneItems(from) {
    for (const eachItem in from) {
      this.graph.forEachNode((node, attributes) => {
        let eachScene = attributes.scene;
        let item = from[eachItem];
        item = item(eachScene);
        eachScene.push(item);
      });
    }
  }
  get name() {
    return this.json.name;
  }
  get version() {
    return this.json.version;
  }
  get defaultScene() {
    return this.json.defaultScene;
  }
  get defaultOrFirstScene() {
    var name = this.json.defaultScene;

    var found = this.findSceneByName(name);

    if (!found) {
      found = this.findFirstScene();
    }
    return found;
  }
  get defaultSceneGraph() {
    return this.json.defaultSceneGraph;
  }

  addScene(name, json, isTemporary = false) {
    var s = new Scene(this, name, json);
    this.graph.addNode(s.name, { scene: s, isTemporary: isTemporary });
  }
  removeScene(name) {
    var node = this.graph.nodes()[name];
    this.graph.dropNode(node);
  }

  initializeGraphFromJsonKey(key, value) {
    if (key == "includes") {
      for (const each in value) {
        var each_value = value[each];
        this.evaluate_json_file(each_value);
      }
    } else if (key == "scenes") {
      for (const sceneskey in value) {
        this.addScene(sceneskey, value[sceneskey]);
      }
    } else if (key == "scenes.audio_visual.state_set") {
      this.audio_visual_state_set = new SceneGraphAudioVisualStateSet(value);
      this.audio_visual_state_set.initializeFromJson();
    } else if (key == "scenes.audio_visual.scripts") {
      this.audio_visual_scripts = new SceneGraphAudioVisualScripts(value);
      this.audio_visual_scripts.initializeFromJson();
      this.active_audio_visual_script = this.audio_visual_scripts.getDefaultScript();
      if(this.active_audio_visual_script){
        this.active_audio_visual_script.startFromBeginning(Date.now());
      }
    }
  }

  async async_fetch_dependencies() {
    var waitFor = [];

    var includes = this.json["includes"];
    if(includes){
      for (const each of includes) {
        var path = this.resources.getDataFilePathByNameAndExtension(each, Resources.JsonExtension);
        waitFor.push(this.resources.getOrFetchJsonPromise(path));
      }
    }

    await Promise.all(waitFor);
  }

  async async_fetch_defaultSceneGraph() {
    var waitFor = [];

    var default_sg = this.json["defaultSceneGraph"];
    if(default_sg){
      var path = this.resources.getNavigationGraphPathFromName(default_sg);
      waitFor.push(this.resources.getOrFetchJsonPromise(path));
    }

    var prereqs = this.json["defaultSceneGraph.prerequisite.data"];
    if (prereqs) {
      for (const each of prereqs) {
        var path = this.resources.getDataFilePathByNameAndExtension(each, Resources.JsonExtension);
        waitFor.push(this.resources.getOrFetchJsonPromise(path));
      }
    }

    await Promise.all(waitFor);
  }

  evaluate_json_file(filename) {
    var file_path = this.resources.getDataFilePathByNameAndExtension(filename, Resources.JsonExtension)
    var json = this.resources.getFetchedJson(file_path);
    for (const key in json) {
      var value = json[key];
      this.initializeGraphFromJsonKey(key, value);
    }
  }
  toPath(scene_name = undefined) {
    return new ScenePath(this.name, scene_name);
  }
  toPathWithDefaultScene() {
    return this.toPath(this.defaultScene);
  }


  findSceneByPath(path, isTemporary = false) {
    return this.findSceneByName(path.sceneName, isTemporary);
  }

  findSceneByName(name, isTemporary = false) {
    for (const { node, attributes } of this.graph.nodeEntries()) {
      if (node == name && attributes.isTemporary == isTemporary) {
        return attributes.scene;
      }
    }
  }

  findFirstScene(isTemporary = false) {
    for (const { node, attributes } of this.graph.nodeEntries()) {
      if (attributes.isTemporary == isTemporary) {
        return attributes.scene;
      }
    }
  }  
}
