import { Action } from "../actions/action";

export class OpenFullscreenModuleAction extends Action {
  value;
  value_context;
  constructor(verb, name, json) {
    super(verb, name);
    this.value = json["value"];
    this.value_context = json["value_context"];
  }

  run(interactive) {
    console.log("open fullscreen module");
    var module = interactive.scene.icanvas.externalModules.openModule(this.value, true);
    module.data_context = this.value_context;

    var account_rewards = interactive.account.addTokenRewardSource(module.name);
    module.addActivationRewards(account_rewards);
  }
}
