import { Interactive } from './Interactive';
import { SceneObject } from './SceneObject';


export class SceneObjectProperty {
}

export class SceneObjects {
  static typename = "objects";
  static baseResourcePath = "sceneObjects";
  parent;
  json;
  items = [];

  constructor(parent, json) {
    this.parent = parent;
    this.json = json;
  }

  initializeFromJson() {
    for (let each in this.json) {
      var i = new SceneObject(this, this.json[each]);
      this.items.push(i);
      i.initializeFromJson();
    }
  }

  get scene() {
    return this.parent.scene;
  }


  start() {
    this.items.forEach((element) => {
      element.start();
    });
  }

  // start_loaded() {
  //   if(this.scene.scene_graph_node==undefined){
  //     return;
  //   }
    
  //   this.items.forEach((element) => {
  //     element.start_loaded();
  //   });
  // }
  stop() {
    this.items.forEach((element) => {
      element.stop();
    });
  }

  drawFrame(icanvas) {
    this.items.forEach((element) => {
      element.drawFrame(icanvas);
    });
  }
}
