import { WebApplication } from '../../webApplication.js';
import { HTML5CanvasElement } from './HTML5CanvasElement.js';
import { VideoFileToCanvasElement } from './VideoFileToCanvasElement.js';
import { VideoFileToVideoElement } from './VideoFileToVideoElement.js';

export class VideoFileToElement extends HTML5CanvasElement {
  video_display;
  state = { isPlaying: false };

  constructor(resource, element, is_invalidating_draw = true) {
    super(resource, element, is_invalidating_draw);
  }

  static isEnabled(webapp) {
    return webapp.getSetting(WebApplication.IsVideoEnabledSettingName);
  }

  static isDrawToCanvasEnabled(webapp) {
    return webapp.getSetting(WebApplication.IsAllCanvasVideosSettingName);
  }


  onStartedError(event) {

    // console.warn("video:element:start:error")

    // this.isError = true;

    this.addError(`video:element:start:error ${event}` + "\n");
    // this.resource_canvas_element?.onResourceCanvasElementError(this);
    //self.onVisualLoaded?.(self);
    // self.resource_element.removeEventListener("error", onError);
    // self.resource_element.removeEventListener("canplay", onCanPlay);

  }


  addingToInteractiveCanvas() {

    var isEnabled = VideoFileToElement.isEnabled(this.icanvas.State.application);

    if (!isEnabled) {
      return this.addError("video not enabled");
    }

    this.element.onError = this.onStartedError.bind(this);
    // this.element.removeEventListener("error", onError);

    if (this.isFirstTimeCanvasAdd) {
      if (this.resource.isAutoPlay) {
        this.playVideo();
      }
    }
    else if (this.element.paused && this.state.isPlaying) {
      this.playVideo();
    }

    var isCanvasDisplayEnabled = VideoFileToElement.isDrawToCanvasEnabled(this.icanvas.State.application);

    if (isCanvasDisplayEnabled) {
      this.video_display = new VideoFileToCanvasElement(this);
    }
    else {
      this.video_display = new VideoFileToVideoElement(this);
    }
    this.video_display.addedToInteractiveCanvas();
  }

  removeingFromInteractiveCanvas() {
    this.video_display?.removedFromInteractiveCanvas();

    this.element.onError = undefined;

    if (!this.element.paused) {
      this.element.pause();
    }

    this.video_display = undefined;
  }

  // autoPlay() {
  //   if (this.resource.isAutoPlay) {
  //     this.playVideo();
  //   }
  // }

  playing;

  playVideo() {

    if (!this.element.paused) {
      return;
    }

    this.state.isPlaying = true;

    console.info("video:element:play");
    this.playing = this.element.play();

    this.playing.catch((e) => {

      var pause_error = "The play() request was interrupted by a call to pause()"
      if (e.message.includes(pause_error)) {
        return;
      }
      this.addError("error:video:element:play" + this.toSourceURL() + "\n" + e);
    });
  }

  draw() {
    super.draw();
    this.video_display.draw();
  }

  // playIfPaused() {
  //   if (this.element.paused) {
  //     this.playVideo();
  //   }
  // }

  onCanvasResized() {
    super.onCanvasResized();
    this.video_display.onCanvasResized?.();
  }

  validate() {
    super.validate();
    this.video_display.validate?.();
  }
}