let c2 = require("c2.js");
import { Geometry } from '../geometry';

//https://c2js.org/docs/classes/geometry.rect.html#constructor

export class RectangleGeometry extends Geometry {
  //rect_pos_size;

  static getJsonEntry(json) {
    return json["geometry.rectangle"];
  }
  get jsonEntry() {
    return RectangleGeometry.getJsonEntry(this.obj.json);
  }
  get rect() {
    return this.jsonEntry;
  }
  get geometry() {
    return this.jsonEntry;
  }
  setPosition(point) {
    this.geometry[0] = point.x;
    this.geometry[1] = point.y;
    this.updateShape();
  }
  constructor(obj) {
    super();
    this.obj = obj;
    this.updateShape();
  }
  updateShape() {
    this.shape = new c2.Rect(
      this.geometry[0],
      this.geometry[1],
      this.geometry[2],
      this.geometry[3]
    );
  }
  to_point() {
    let absolute_shape = this.get_absolute_shape();

    return absolute_shape.p;
  }
  draw(icanvas, drawScope) {
    let absolute_shape = this.get_absolute_shape();
    icanvas.draw_point(absolute_shape.p, 3, drawScope);
    icanvas.draw_rect(absolute_shape, drawScope, this.border_size);
  }

  get_absolute_shape() {

    var result;

    if (this.isRelativeTo) {
      //try {
      let relativeTo = this.isRelativeTo.toRect();
      result = new c2.Rect(
        relativeTo.w * this.shape.p.x,
        relativeTo.h * this.shape.p.y,
        relativeTo.w * this.shape.w,
        relativeTo.h * this.shape.h
      );

      //result = result;
      // }
      // catch (err) {
      // console.warn("error:rectangle: get_absolute_shape");
      //return new c2.Rect(0,0,0,0);
      //  }
    } else {

      result = this.shape;
    }

    var scaler = this.scale;
    if (scaler != 1) {
      result.w = result.w * scaler;
      result.h = result.h * scaler;
    }

    return result;
  }
  get_absolute_rect_shape() {
    return this.get_absolute_shape();
  }

  containsPoint(point) {

    let absolute_shape = this.get_absolute_shape();
    return absolute_shape.intersects(point);
  }
}
