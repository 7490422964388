let c2 = require("c2.js");
import { Geometry } from '../geometry';

//https://c2js.org/docs/classes/geometry.rect.html#constructor

export class PointWithMediaGeometry extends Geometry {
  // pos;
  get get_media() {
    return this.obj.visual_element.visual_resource;
  }
  get get_media_pixel_size() {
    var result = undefined;

    var resource = this.obj.visual_element?.visual_resource;
    if (resource) {
      result = resource.pixel_size();
    }

    if (result == undefined) {
      var file_info = this.obj.visual_element.get_first_file_info();
      if (file_info && file_info.width !== undefined) {
        result = [file_info.width, file_info.height];
      }
    }

    if (result == undefined) {
      result = [0, 0];
    }

    return result;
  }

  static getJsonEntry(json) {
    return json["geometry.point"];
  }
  get jsonEntry() {
    return PointWithMediaGeometry.getJsonEntry(this.obj.json);
  }
  get pos() {
    return this.jsonEntry;
  }
  get geometry() {
    return this.jsonEntry;
  }
  setPosition(point) {
    this.geometry[0] = point.x;
    this.geometry[1] = point.y;
    this.updateShape();
  }
  constructor(obj) {
    super();
    this.obj = obj;
    this.updateShape();
  }

  updateShape() {
    this.shape = new c2.Point(
      this.geometry[0],
      this.geometry[1]
    );
  }

  to_point() {
    let absolute_shape = this.get_absolute_shape();

    return absolute_shape.p;
  }

  draw(icanvas, drawScope) {
    let absolute_shape = this.get_absolute_shape();
    icanvas.draw_point(absolute_shape.p, 3, drawScope);
    icanvas.draw_rect(absolute_shape, drawScope);
  }

  get_absolute_shape() {

    //var media = this.get_media();

    //if (media && !media.isLoading()) {
    var size_px = this.get_media_pixel_size;

    if (this.isRelativeTo) {
      let relativeTo = this.isRelativeTo.toRect(false);

      size_px[0] = relativeTo.w / size_px[0];
      size_px[1] = relativeTo.h / size_px[1];

      // size_px[0] =  relativeTo.w/size_px[0] ;
      // size_px[1] =  relativeTo.h/size_px[1] ;
    }

    var rect_shape = new c2.Rect(this.shape.x, this.shape.y, size_px[0], size_px[1]);

    // } else {
    //   var rect_shape = new c2.Rect(this.shape.x, this.shape.y, 0, 0);
    //   //  var size_px = [0,0];
    // }

    if (this.isRelativeTo) {
      let relativeTo = this.isRelativeTo.toRect();
      let result = new c2.Rect(
        relativeTo.p.x + relativeTo.w * rect_shape.p.x,
        relativeTo.p.y + relativeTo.h * rect_shape.p.y,
        relativeTo.w * rect_shape.w,
        relativeTo.h * rect_shape.h
      );

      return result;
    }

    return rect_shape;
  }

  get_absolute_rect_shape() {
    return this.get_absolute_shape();
  }

  containsPoint(point) {

    let absolute_shape = this.get_absolute_shape();
    return absolute_shape.intersects(point);
  }
}
