
export class VideoDisplayToElement {
  video_file_to_element;
  
  get element() {
    return this.video_file_to_element.element;
  }
  get icanvas() {
    return this.video_file_to_element.icanvas;
  } 
   get resource() {
    return this.video_file_to_element.resource;
  }

  constructor(video_file_to_element) {
    this.video_file_to_element = video_file_to_element;
  }
  addedToInteractiveCanvas() { }
  removedFromInteractiveCanvas() {
  }
  draw() { }
}
