export class ModuleIntegration {
  openModule;
  constructor(openModule) {
    this.openModule = openModule;
  }

  get data_context() {
    return this.openModule.data_context;
  }
  sendMessageToModule(message) {
    this.openModule.iframe.contentWindow.postMessage(message, "*");
  }
}
