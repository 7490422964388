import { Player } from "../Player";
import { ScenePath } from "./ScenePath";
import { SceneGraph } from "./scene_graph";
import { EmptySceneGraphNode, SceneGraphNode } from "./scene_graph_node";

export class SceneGraphSet {
  simulation;
  scene_graphs = [];
  default_scene_graph;

  constructor(simulation) {
    this.simulation = simulation;
  }

  get resources() {
    return this.simulation.resources;
  }
  get sceneGraphNode() {
    return this.player.sceneGraphNode;
  }
  get player() {
    return this.simulation.player;
  }
  get globalSceneGraph() {
    return this.default_scene_graph;
  }
  get defaultNavigationSceneGraphName() {
    return this.globalSceneGraph.defaultSceneGraph;
  }
  get defaultNavigationSceneGraph() {
    return this.getSceneGraph(this.defaultNavigationSceneGraphName);
  }

  async initialize() {
    var default_scene_graph_json = this.resources.getFetchedJson(this.resources.getNavigationGraphPathFromName(""));

    this.default_scene_graph = new SceneGraph(this, default_scene_graph_json);
    this.default_scene_graph.initializeFromJson();
   // await this.default_scene_graph.async_fetch_defaultSceneGraph();

  }

  // tryPopulateDefaultPaths(path) {
  //   if (path.sceneName == undefined) {
  //     var graph = this.getSceneGraphByPath(path);
  //     if (graph) {
  //       path.sceneName = graph.json.defaultScene;
  //     }
  //   }
  // }

  getSceneGraphByIndex(index) {
    return this.scene_graphs[index];
  }

  getSceneGraphByPath(path) {
    return this.getSceneGraph(path.sceneGraphName);
  }

  getSceneGraphSceneByPath(path) {
    var graph = this.getSceneGraphByPath(path);


    return graph?.findSceneByPath(path);
  }

  getSceneGraphSceneByPath(path) {
    var graph = this.getSceneGraphByPath(path);

    return graph?.findSceneByPath(path);
  }

  // initializeGraphNodeScene(node) {
  //   var common = this.globalSceneGraph.json.sceneGraphSetCommon;
  //   if (!common) {
  //     return;
  //   }
  //   var layers = common.sceneGraphLayers;
  //   if (!common) {
  //     return;
  //   }

  //   layers.forEach((element) => {
  //     if (element.sceneGraphName) {
  //       var found = this.getSceneGraph(element.sceneGraphName);
  //       if (found) {
  //         node.addAndStart(found.defaultOrFirstScene);
  //       }
  //     }
  //   });
  // }

  isSceneGraphFetched(name) {
    var path = this.resources.getNavigationGraphPathFromName(name);
    return this.resources.isJsonFetched(path);
  }

  fetchSceneGraphPromise(name) {
    for (let index = 0; index < this.scene_graphs.length; index++) {
      const element = this.scene_graphs[index];
      if (element.name === name) {
        return Promise.resolve(element);
      }
    }

    var path = this.resources.getNavigationGraphPathFromName(name);
    let p = this.resources.getOrFetchJsonPromise(path);
    p = p.then(async (result) => {
      if (result == 404) {
        return result;
      }
      var newSceneGraph = this.pushNewSceneGraphFromJson(path, result);

      if (newSceneGraph) {
        await newSceneGraph.async_fetch_dependencies();
        newSceneGraph.initializeFromJson();
      }

      return result;
    });

    return p;
  }

  pushNewSceneGraphFromJson(path, json) {
    if (json === undefined) {
      return undefined;
    }

    var newSceneGraph = new SceneGraph(this.simulation, json, this.globalSceneGraph, path, this.scene_graphs.length);

    this.scene_graphs.push(newSceneGraph);
    return newSceneGraph;
  }

  getSceneGraph(name) {
    for (let index = 0; index < this.scene_graphs.length; index++) {
      const element = this.scene_graphs[index];
      if (element.name === name) {
        return element;
      }
    }

    var path = this.resources.getNavigationGraphPathFromName(name);
    let json = this.resources.getFetchedJson(path);
    var result = this.pushNewSceneGraphFromJson(path, json);
    result.initializeFromJson();
    return result;
  }
}