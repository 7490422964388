import { Stem } from "../../audio_visual/audio/stem";
import { WebAudio } from '../../audio_visual/audio/webAudio';
import { Action } from "../actions/action";

export class PlayAudioAction extends Action {
  value;
  value_context;

  constructor(verb, name, json) {
    super(verb, name);
    this.value = json["value"];
    this.value_context = json["value_context"];
  }

  newSoundEffectStem(obj, json) {
    var result = new Stem(json, obj.getResourcePathFunction());
    result.json.isLoop = false; 
    result.json.layer = WebAudio.effectslayerName;
    return result;
  }

  run(interactive) {
    var stems = [];
    var obj=interactive.scene || interactive

    if (Array.isArray(this.value)) {
      this.value.forEach((element) => {
        stems.push(this.newSoundEffectStem(obj, element));
      });
    } else {
      stems.push(this.newSoundEffectStem(obj, this.value));
    }

    obj.simulation.audio.playSoundEffects(stems);
  }
}
