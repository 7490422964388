import { SceneGraphAudioVisualState } from './SceneGraphAudioVisualState';

export class SceneGraphAudioVisualStateSet{
    json;

    constructor(json) {
        this.json = json;
    }

    initializeFromJson() {
    }

    getNewStateObjectByName(propertyName) {
        if (this.hasOwnProperty(propertyName)) {
          var data = this.json[propertyName];
          var obj=new SceneGraphAudioVisualState(data);
          obj.initializeFromJson();
          return obj;
        } else {
          // Handle cases where the property might not exist
          console.warn(`Property '${propertyName}' not found.`);
          return null; // Or throw an error if appropriate
        }
      }
}

