import { v4 as uuidv4 } from "uuid";
import * as htmlHelper from "./../htmlHelper.js";

// https://api.jqueryui.com/category/widgets/

export class EditableProperty {
  name;
  help;
  defaultValue;
  getValue;
  setValue;
  is_enabled = true;
  valueChanged() {}

  getLabelElement() {
    var text = `<span disabled=${!this.is_enabled}>${this.name}</span>`;
    let result = htmlHelper.htmlToElement(text);

    return result;
  }
  getValueElements() {
    return [];
  }
  getEditorElement() {
    let items = this.getEditorElements();
    let result = htmlHelper.withBorderlessTableRow(items);
    return result;
  }
  getEditorElements() {
    var values = this.getValueElements();
    values.forEach((element) => {
      element.style.marginLeft = "8px";
    });

    var result = [this.getLabelElement(), ...values];

    return result;
  }
  setEnabled(value) {}
}

export class ReadonlyProperty extends EditableProperty {
  constructor() {
    super();
  }

  getValueElements() {
    let result = super.getValueElements();
    let value = this.getValue();
    let element = htmlHelper.htmlToElement(`<span >${value}</span>`);
    result.push(element);

    return result;
  }
}

//https://jqueryui.com/spinner/#decimal
//https://api.jqueryui.com/spinner/

export class NumberEditableProperty extends EditableProperty {
  constructor() {
    super();
  }
  minValue;
  maxValue;
  spinner;
  slider;

  getValueElements() {
    let result = super.getValueElements();

    let id = this.name + uuidv4();

    let get = this.getValue;
    let value = get();

    this.spinner = Object.assign(document.createElement("input"), {
      id: id,
      type: "number",
      step: 0.1,
      class: "form-control",
      value: value,
    });

    let self = this;
    this.spinner.onchange = (event) => {
      self.setValue(event.target.value);
    };

    this.spinner.style.width = "64px";
    result.push(this.spinner);

    if (this.minValue != undefined || this.maxValue != this.maxValue) {
      this.spinner.min = this.minValue;
      this.spinner.max = this.maxValue;
      //   let slider_id = self.name + uuidv4();

      //   this.slider = Object.assign(document.createElement("input"), {
      //     id: slider_id,
      //     type: "range",
      //     class: "form-range",
      //     value: value,
      //     step: 0.1,
      //     min: this.minValue,
      //     max: this.maxValue,
      //   });
      //  result.push(this.slider);
    }

    return result;
  }
}

//https://www.w3schools.com/jsref/prop_checkbox_checked.asp

export class BooleanEditableProperty extends EditableProperty {
  constructor() {
    super();
  }
  html_element;

  getValueElements() {
    let result = super.getValueElements();

    let id = this.name + uuidv4();

    let get = this.getValue;
    let value = get();

    this.html_element = Object.assign(document.createElement("input"), {
      id: id,
      type: "checkbox",
      checked: value,
      disabled: !this.is_enabled,
    });


    let self = this;
    this.html_element.onchange = (event) => {
      var v = event.target.checked;
      self.setValue(v);
    };

    result.push(this.html_element);

    return result;
  }

  setEnabled(value) {
    this.is_enabled = value;
    if( this.html_element){
    this.html_element.disabled = !value;

    }
  }
}

export class PropertyEditor {}
