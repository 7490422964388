
export class MouseEvent {
  e;
  x;
  y;
  constructor(e) {
    this.e = e;
    this.x = e.offsetX;
    this.y = e.offsetY;
  }
}
