import { FileResource } from './resources/FileResource';
import { AssetDatabase, IndexedDBAssetDataInterface } from "./server/shared/AssetDatabase";
import { Treeview } from "./view/treeview";

export class LocalServerFileCache {
  server;
  server_asset_list;
  server_asset_lookup;
  db_promise;
  //db_upgrade_promise;
  db;
  db_version;
  asset_database;
  application;

  constructor(server, application) {
    this.server = server;
    this.application = application;
    this.asset_database = new AssetDatabase();
  }
  get_additional_manifest_entries_for_file_request(request) {
    var result = [];
    var look_for = FileResource.getPathWithoutExtension(request.fullpath);
    look_for += ".";

    for (let index = 0; index < this.server_asset_list.length; index++) {
      const each = this.server_asset_list[index];
      var each_path = each.path;

      if (!each_path || each_path === request.fullpath) {
        continue;
      }

      // each_path = FileResource.getPathWithoutExtension(each_path);

      if (each_path.startsWith(look_for)) {

        result.push(each_path);
      }
    }
    return result;
  }

  findFile(path) {
    var found = this.server_asset_lookup[path];
    return found;
  }
  collectEditableProperties(layout) {
    if (this.server_asset_list) {
      let server_asset_list_view = new Treeview();
      server_asset_list_view.buildElementFromJson(this.server_asset_list, 1);
      layout.add(server_asset_list_view.element);
    }
  }

  createNewDatabase(database, resolve) {
    database.createObjectStore(AssetDatabase.storeName, { keyPath: "path" /*, autoIncrement: true*/ });
    resolve();
  }

  async initialize() {
    self = this;

    var fromLocalStorage = this.server.resources.getLocalStorageJsonItem(this.stroageItemName());
    this.asset_database.loadFromJson(fromLocalStorage);

    this.server_asset_list = this.server.resources.getFetchedJson(this.server.resources.getFileManifestJsonPath());

    this.server_asset_lookup = this.server_asset_list.reduce((acc, item) => {
      if (item.path) {
        acc[item.path] = item;
      }
      return acc;
    }, {});

    if (!this.application.getSetting("isPreCacheAssets")) {
      return;
    }

    this.db_promise = new Promise((resolve, reject) => {
      const request = window.indexedDB.open(AssetDatabase.dbName, 5);

      request.onerror = (event) => {
        console.error("error: indexedDB");
        reject(request.error); // Rej
      };

      request.onsuccess = (event) => {
        console.log("using: indexedDB");
        resolve(request.result);
        // self.db = self.db_promise.result;
      };

      request.onupgradeneeded = (event) => {
        //this.db_upgrade_promise = new Promise((resolve) => {
        let db = event.target.result;

        // if (db.objectStoreNames.contains(AssetDatabase.storeName)) {
        //   db.deleteObjectStore(AssetDatabase.storeName);
        //   self.createNewDatabase(db, resolve);
        // } else {
        //   self.createNewDatabase(db, resolve);
        // }
        //  });
      };
    });

    try {
      this.db = await this.db_promise;
    } catch (error) {
      console.error('Error opening database:', error);
      return;
    }

    if (this.db) {
      this.asset_database.setDataInterface(new IndexedDBAssetDataInterface(this.db));

      //await this.asset_database.downloadNewAssets(this.server_asset_list);
    }
  }

  stroageItemName() {
    return this.server.resources.combineJsonResourceName(this.server.application.name, "server_file.storage");
  }
  saveState() {
    //this.asset_database.json.db_version = this.db.version;
    var asset_db = this.asset_database.saveToJson();
    //asset_db={};
    this.server.resources.setLocalStorageItemAsJson(this.stroageItemName(), asset_db);
  }
  shutdown() {
    this.saveState();
  }


  startFetchPromise(url) {
  }

  loadFromCacheOrUrl(assetObject, url) {
    var is_enabled = this.application.getSetting("isPreCacheAssets");
    this.asset_database.loadFromCacheOrUrl(assetObject, url, is_enabled);
  }
}
