
export const SizeType = {
  ScaleToFitScreen: 0,
  ScaleToFitRectangle: 1,
  Normal: 2,
};

export function parseSizeType(sizeTypeString) {
  // Convert the input string to lowercase for case-insensitive matching
  const lowerCaseSizeType = sizeTypeString.toLowerCase();

  // Use a switch statement for clarity and to handle different string formats
  switch (lowerCaseSizeType) {
    case "scaletofitscreen":
    case "0":
      return SizeType.ScaleToFitScreen;
    case "scaletofitrectangle":
    case "1":
      return SizeType.ScaleToFitRectangle;
    case "normal":
    case "2":
      return SizeType.Normal;
    default:
      // Handle invalid input gracefully (e.g., return null or throw an error)
      console.error("Invalid SizeType string:", sizeTypeString);
      return null; // Or throw new Error(...) 
  }
}