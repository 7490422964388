export class CachedAction {
    static typename = "cachedAction";
    name;
    context;
    now;

    constructor(name, context, now) {
      this.name = name;
      this.context = context;
      this.now = now;
    }
}