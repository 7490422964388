
export class AmbianceContext {
  scope;
  constructor(scope = undefined) {
    this.scope = scope;
  }

  get area() {
    return this.scope?.area;
  }

  get region() {
    return this.scope?.region;
  }
  get regionName() {
    return this.region?.name;
  }
  get areaName() {
    return this.area?.name;
  }
}
