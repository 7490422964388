import { ReadonlyProperty } from "./view/propertyEditor";
import { StackLayout } from "./view/stackLayout";
import { AuthorInterface } from "./sceneAuthorInterface/authorInterface";
import { ValueChangedSubscriptions } from './ValueChangedSubscriptions';
import { MemoryDiagnostics } from './MemoryDiagnostics';

export class WebApplication {


  static isVideoPlayingTimeoutSettingName = "video playing timeout";
  static isVideoPlayingTimeoutSettingDefaultValue = false;

  static isShowMemoryDiagnosticsOverlaySettingName = "Show Memory Diagnostics Overlay";
  static isShowMemoryDiagnosticsOverlaySettingDefaultValue = false;
  //static isMemoryDiagnosticsOverlayTimeoutSettingName = "Memory Diagnostics Overlay Inactivity Timeout Minutes";
  //static isMemoryDiagnosticsOverlayTimeoutSettingDefaultValue=5;

  static isShowConsoleLogOverlaySettingName = "Show Console Log Overlay";
  static isShowConsoleLogOverlaySettingDefaultValue = false;
  static isConsoleLogOverlayTimeoutSettingName = "Console Log Overlay Inactivity Timeout Minutes";
  static isConsoleLogOverlayTimeoutSettingDefaultValue = 5;

  static isShowDebugOverlaySettingName = "Show Debug Overlay";
  static isShowDebugOverlaySettingDefaultValue = true;

  static isShowDetailedDebugOverlaySettingName = "Show Detailed Debug Overlay";
  static isShowDetailedDebugOverlaySettingDefaultValue = false;

  static isDebugOverlayTimeoutSettingName = "Debug Overlay Inactivity Timeout Minutes";
  static isDebugOverlayTimeoutSettingDefaultValue = 0.5;

  static isLoadApplicationLocalStorageSettingName = "isLoadApplicationLocalStorage";
  static isLoadApplicationLocalStorageSettingDefaultValue = true;
  static isSaveApplicationLocalStorageSettingName = "isSaveApplicationLocalStorage";
  static isSaveApplicationLocalStorageSettingDefaultValue = true;
  static isDeleteBeforeSaveApplicationLocalStorageSettingName = "isDeleteBeforeSaveApplicationLocalStorage";
  static isDeleteBeforeSaveApplicationLocalStorageSettingDefaultValue = true;


  static isLoadAccountLocalStorageSettingName = "isLoadAccountLocalStorage";
  static isLoadAccountLocalStorageSettingDefaultValue = false;
  static isSaveAccountLocalStorageSettingName = "isSaveAccountLocalStorage";
  static isSaveAccountLocalStorageSettingDefaultValue = false;
  static isPreCacheAssetsSettingName = "isPreCacheAssets";
  static isPreCacheAssetsSettingDefaultValue = false;
  static isUseRESTSettingName = "isUseREST";
  static isUseRESTSettingDefaultValue = false;

  static IsVerboseLoggingSettingName = "verbose logging"
  static IsVerboseLoggingSettingDefaultValue = true;

  static IsVideoEnabledSettingName = "video enabled"
  static IsVideoEnabledSettingDefaultValue = true;

  static IsDBVideoEnabledSettingName = "double buffered video enabled"
  static IsDBVideoEnabledSettingDefaultValue = false;

  static IsSBVideoEnabledSettingName = "single buffered video enabled"
  static IsSBVideoEnabledSettingDefaultValue = true;

  static IsPSVideoEnabledSettingName = "per-scene video enabled"
  static IsPSVideoEnabledSettingDefaultValue = false;

  static IsAllCanvasVideosSettingName = "per-scene canvas video enabled"
  static IsAllCanvasVideosSettingDefaultValue = false;

  static VideoFramesPerSecondSettingName = "per-scene canvas video Frames Per Second"
  static VideoFramesPerSecondSettingDefaultValue = 30;

  static IsAuthEnabledSettingName = "auth enabled"
  static IsAuthEnabledSettingDefaultValue = true;

  static IsAudioEnabledSettingName = "audio enabled"
  static IsAudioEnabledSettingDefaultValue = true;

  static IsWebpEnabledSettingName = "WebP enabled"
  static IsWebpEnabledSettingDefaultValue = true;

  static IsWebpWebAssemblyEnabledSettingName = "WebP web assembly enabled"
  static IsWebpWebAssemblyEnabledSettingDefaultValue = false;

  resources;
  name;
  json;
  versionNumber;
  setting_subscriptions = {};
  server;
  memory_diagnostics;
  platform_canvas;

  constructor(resources, name = "", versionNumber = undefined) {
    this.resources = resources;
    this.name = name;
    this.versionNumber = versionNumber;
    this.memory_diagnostics = new MemoryDiagnostics(this);
  }

  setServer(server) {
    this.server = server;
  }

  stroageItemName() {
    return this.resources.combineJsonResourceName(this.name, "application.storage");
  }

  initialize() {
    this.json = {};
    this.addSettings();

    //this.shutdown();

    var is_load_default = this.getSetting(WebApplication.isLoadApplicationLocalStorageSettingName);

    if (is_load_default) {
      var saved_settings = this.resources.getLocalStorageJsonItem(this.stroageItemName(), false);
      if (saved_settings) {
        // var isload = saved_settings.settings[WebApplication.isLoadApplicationLocalStorageSettingName];

        //if (isload == true) {
        this.json.settings = saved_settings.settings;
        //}
      }

    } else {

      var saved_settings = this.resources.getLocalStorageJsonItem(this.stroageItemName(), false);

      if (saved_settings) {
        var isload = saved_settings.settings[WebApplication.isLoadApplicationLocalStorageSettingName];
        var issaved = saved_settings.settings[WebApplication.isSaveApplicationLocalStorageSettingName];

        if (isload == true && issaved == true) {
          this.json.settings = saved_settings.settings;
        }
      }
    }


    // if (/*this.getSetting("isLoadLocalStorage") == false || */this.getSetting("isLoadApplicationLocalStorage") == false) {
    //   this.json = {};
    //   this.addSettings();
    // }
    // this.saveState();
  }

  addSettings() {
    if (!this.json.hasOwnProperty("settings")) {
      this.json.settings = {};
    }
    if (!this.json.hasOwnProperty("default")) {
      this.json.default = {};
      this.json.default.settings = {};
    }
    if (!this.json.hasOwnProperty("ui")) {
      this.json.ui = {};
      this.json.ui.settings = {};
    }
    this.addSettingToJson(WebApplication.isShowConsoleLogOverlaySettingName, WebApplication.isShowConsoleLogOverlaySettingDefaultValue);
    this.addSettingToJson(WebApplication.isConsoleLogOverlayTimeoutSettingName, WebApplication.isConsoleLogOverlayTimeoutSettingDefaultValue);

    this.addSettingToJson(WebApplication.isShowDebugOverlaySettingName, WebApplication.isShowDebugOverlaySettingDefaultValue);
    this.addSettingToJson(WebApplication.isShowDetailedDebugOverlaySettingName, WebApplication.isShowDetailedDebugOverlaySettingDefaultValue);
    this.addSettingToJson(WebApplication.isShowMemoryDiagnosticsOverlaySettingName, WebApplication.isShowMemoryDiagnosticsOverlaySettingDefaultValue);
    this.addSettingToJson(WebApplication.isDebugOverlayTimeoutSettingName, WebApplication.isDebugOverlayTimeoutSettingDefaultValue);

    this.addSettingToJson(WebApplication.isLoadApplicationLocalStorageSettingName, WebApplication.isLoadApplicationLocalStorageSettingDefaultValue);
    this.addSettingToJson(WebApplication.isSaveApplicationLocalStorageSettingName, WebApplication.isSaveApplicationLocalStorageSettingDefaultValue);
    this.addSettingToJson(WebApplication.isDeleteBeforeSaveApplicationLocalStorageSettingName, WebApplication.isDeleteBeforeSaveApplicationLocalStorageSettingDefaultValue);


    this.addSettingToJson(WebApplication.isLoadAccountLocalStorageSettingName, WebApplication.isLoadAccountLocalStorageSettingDefaultValue);
    this.addSettingToJson(WebApplication.isSaveAccountLocalStorageSettingName, WebApplication.isSaveAccountLocalStorageSettingDefaultValue);
    this.addSettingToJson(WebApplication.isPreCacheAssetsSettingName, WebApplication.isPreCacheAssetsSettingDefaultValue);
    this.addSettingToJson(WebApplication.isUseRESTSettingName, WebApplication.isUseRESTSettingDefaultValue);

    this.addSettingToJson(WebApplication.IsVerboseLoggingSettingName, WebApplication.IsVerboseLoggingSettingDefaultValue);

    this.addSettingToJson(WebApplication.IsVideoEnabledSettingName, WebApplication.IsVideoEnabledSettingDefaultValue);

    this.addSettingToJson(WebApplication.IsDBVideoEnabledSettingName, WebApplication.IsDBVideoEnabledSettingDefaultValue);
    this.addSettingToJson(WebApplication.IsSBVideoEnabledSettingName, WebApplication.IsSBVideoEnabledSettingDefaultValue);
    this.addSettingToJson(WebApplication.IsPSVideoEnabledSettingName, WebApplication.IsPSVideoEnabledSettingDefaultValue);

    this.addSettingToJson(WebApplication.IsAllCanvasVideosSettingName, WebApplication.IsAllCanvasVideosSettingDefaultValue);
    this.addSettingToJson(WebApplication.VideoFramesPerSecondSettingName, WebApplication.VideoFramesPerSecondSettingDefaultValue);

    this.addSettingToJson(WebApplication.IsAuthEnabledSettingName, WebApplication.IsAuthEnabledSettingDefaultValue);
    this.addSettingToJson(WebApplication.IsAudioEnabledSettingName, WebApplication.IsAudioEnabledSettingDefaultValue);
    this.addSettingToJson(WebApplication.IsWebpEnabledSettingName, WebApplication.IsWebpEnabledSettingDefaultValue);
    this.addSettingToJson(WebApplication.isVideoPlayingTimeoutSettingName, WebApplication.isVideoPlayingTimeoutSettingDefaultValue);

    //this.addSettingToJson(WebApplication.IsWebpWebAssemblyEnabledSettingName, WebApplication.IsWebpWebAssemblyEnabledSettingDefaultValue);
  }
  static MinSuffix = "_min";
  static MaxSuffix = "_max";

  addSettingToJson(name, defaultValue = undefined, minValue = undefined, maxValue = undefined) {

    if (!this.json.default.settings.hasOwnProperty(name)) {
      this.json.default.settings[name] = defaultValue;
      if (minValue != undefined) {
        this.json.default.settings[name + WebApplication.MinPrefix] = minValue;
      }
      if (maxValue != undefined) {
        this.json.default.settings[name + WebApplication.MaxSuffix] = maxValue;
      }
    }
    if (!this.json.ui.settings.hasOwnProperty(name)) {
      this.json.ui.settings[name] = {};
    }
  }

  addSettingSubscription(name, onChanged) {
    if (name == undefined || onChanged == undefined) {
      return;
    }
    ValueChangedSubscriptions.addToObjectArrayPropertyByName(this.setting_subscriptions, name, onChanged);
  }
  removeSettingSubscription(name, onChanged) {
    if (name == undefined || onchange == undefined) {
      return;
    }
    ValueChangedSubscriptions.removeFromObjectArrayPropertyByName(this.setting_subscriptions, name, onChanged);
  }

  setSetting(property, v, isNotify = true) {
    this.json.settings[property] = v;
    if (this.setting_subscriptions && isNotify == true) {
      ValueChangedSubscriptions.updateValueByName(this.setting_subscriptions, property, v);
    }
  }
  setDefaultSetting(property, v/*, isNotify = true*/) {
    this.json.default.settings[property] = v;
    // if (this.setting_subscriptions && isNotify == true) {
    //   ValueChangedSubscriptions.updateValueByName(this.setting_subscriptions, property, v);
    // }
  }
  toggleBooleanSetting(name) {
    var s = this.getSetting(name);
    if (s == undefined) {
      return;
    }

    this.setSetting(name, !s);

  }
  getSetting(name, onChanged = undefined) {
    if (this.json.settings.hasOwnProperty(name)) {
      return this.json.settings[name];
    }
    if (this.json.default.settings.hasOwnProperty(name)) {
      return this.json.default.settings[name];
    }
    return undefined;
  }

  get settings() {
    return this.json.settings;
  }

  saveState() {
    var is_cleanup = this.getSetting(WebApplication.isDeleteBeforeSaveApplicationLocalStorageSettingName);

    if (is_cleanup) {
      this.resources.deleteLocalStorageItem(this.stroageItemName());
    }

    var is_save = this.getSetting(WebApplication.isSaveApplicationLocalStorageSettingName);

    if (is_save) {
      let json_copy = Object.assign({}, this.json, {});
      json_copy.ui = undefined;
      json_copy.default = undefined;
      this.resources.setLocalStorageItemAsJson(this.stroageItemName(), json_copy);
    }
  }

  shutdown() {
    this.saveState();
  }

  collectEditableProperties(layout) {
    let version_property = Object.assign(new ReadonlyProperty(), {
      name: "version",
      getValue: () => {
        return this.versionNumber;
      },
    });
    layout.addAsTableRow(version_property.getEditorElements());

    layout.addAsTableRow(
      Object.assign(new ReadonlyProperty(), {
        name: "url",
        getValue: () => {
          return window.location.href;
        },
      }).getEditorElements()
    );
  }

  getAuthorInterfaceName() {
    return this.name;
  }

  createAuthorInterfaceElement() {
    let layout = new StackLayout();
    this.collectEditableProperties(layout);
    AuthorInterface.collectEditablePropertiesFromProperties(this.json.settings, this.json.ui.settings, this.json.default.settings, layout, this.setting_subscriptions);
    return layout.element;
  }

  addAuthorInterfaceElementToTreeview(treeview) {
    let elm = this.createAuthorInterfaceElement();
    treeview.addItem(this.getAuthorInterfaceName(), elm, true);
  }

  initializeExternalModules(state) {

  }
  initializeInternalModules(state) {

  }
  initializeSceneModules(state) {

  }
}
