export class SceneGraphAudioVisualScriptItem {
    json;
    event_properties = {};

    static EventsPropertyNamePrefix = "audio_visual.state.events.";

    constructor(json) {
        this.json = json;
    }

    initializeFromJson() {
        for (let each in this.json) {
            if (each.startsWith(SceneGraphAudioVisualScriptItem.EventsPropertyNamePrefix)) {
                var str = each.slice(SceneGraphAudioVisualScriptItem.EventsPropertyNamePrefix.length);

                const dotIndex = str.indexOf('.');
                if (dotIndex !== -1) {
                    const event_name = str.slice(0, dotIndex);
                    const property_name = str.slice(dotIndex + 1);
                    var found = this.event_properties[event_name];
                    if (found == undefined) {
                        found = {};
                        this.event_properties[event_name] = found;
                    }
                    found[property_name] = this.json[each];
                }
            }
        }
    }

    get audio_visual_state() {
        return this.json["audio_visual.state"];
    }
    get duration_minutes() {
        return this.json["duration.minutes"];
    }
    get occurrence_percentage() {
        return this.json["occurrence_percentage"];
    }
}
