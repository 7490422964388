
export class UserInterfaceElement {
    elements;
    json;

    constructor(elements, json) {
        this.elements = elements;
        this.json = json;
    }

    addToDOM() { }
}
