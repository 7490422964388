import { InteractiveEvent } from "./InteractiveEvent";
import { InteractiveLayer } from "./InteractiveLayer";

export class InteractiveLayers {
  static DefaultLayerJson = { name: "default" };
  default_layer;
  items = [];
  parent;
  constructor(parent) {
    this.parent = parent;
  }

  get scene() {
    return this.parent.scene;
  }

  getResourcePath() {
    return this.parent.getResourcePath();
  }
  pushLayer(json) {
    this.items.push(new InteractiveLayer(this, json));
  }
  setDefaultLayer(json=undefined){
    // if(json==undefined){
    //   json={};
    // }
    // if(json.name==undefined){
    //   json.name=InteractiveLayers.DefaultLayerJson.name;
    // }
    this.default_layer = new InteractiveLayer(this, json);
    this.items.push(this.default_layer);
  }
  initializeFromJson() {
    for (let each in this.items) {
      this.items[each].initializeFromJson();    
    }
  }

  // pushDefaultLayer() {
  //   this.default_layer = new InteractiveLayer(this.scene, { ...InteractiveLayers.DefaultLayerJson });
  //   this.items.push(this.default_layer);
  // }

  // addInteractive(value) {
  //   if (value.json.layer === undefined) {
  //     value.json.layer = InteractiveLayers.DefaultLayerJson.name;
  //   }
  //   for (let index = 0; index < this.items.length; index++) {
  //     const element = this.items[index];
  //     if (element.json.name == value.json.layer) {
  //       element.interactives.add(value);
  //       break;
  //     }
  //   }
  // }
  activate_event(event) {
    for (let each in this.items) {
      this.items[each].activate(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  activate(value, value_context) {
    let event = new InteractiveEvent();
    event.activate_value = value;
    event.activate_value_context = value_context;
    this.activate_event(event);
  }

  start() {
    this.items.forEach((element) => {
      element.start();
    });
  }

  // start_loaded() {
  //   if(this.scene.scene_graph_node==undefined){
  //     return;
  //   }
    
  //   this.items.forEach((element) => {
  //     element.start_loaded();
  //   });
  // }
  stop() {
    this.items.forEach((element) => {
      element.stop();
    });
  }

  drawFrame(icanvas) {
    this.items.forEach((element) => {
      element.drawFrame(icanvas);
    });
  }
  mousedown(event) {
    for (var each = this.items.length - 1; each >= 0; each--) {
      this.items[each].mousedown(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  mouseup(event) {
    for (var each = this.items.length - 1; each >= 0; each--) {
      this.items[each].mouseup(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  mousemove(event) {
    for (var each = this.items.length - 1; each >= 0; each--) {
      this.items[each].mousemove(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  keydown(event) {
    for (var each = this.items.length - 1; each >= 0; each--) {
      this.items[each].keydown(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  keyup(event) {
    for (var each = this.items.length - 1; each >= 0; each--) {
      this.items[each].keyup(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  onTouchTap(event) {
    for (var each = this.items.length - 1; each >= 0; each--) {
      this.items[each].onTouchTap(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  onTouchPan(event) {
    for (var each = this.items.length - 1; each >= 0; each--) {
      this.items[each].onTouchPan(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  onTouchSwipe(event) {
    for (var each = this.items.length - 1; each >= 0; each--) {
      this.items[each].onTouchSwipe(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  onTouchDistance(event) {
    for (var each = this.items.length - 1; each >= 0; each--) {
      this.items[each].onTouchDistance(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  onTouchRotate(event) {
    for (var each = this.items.length - 1; each >= 0; each--) {
      this.items[each].onTouchRotate(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  onTouchGesture(event) {
    for (var each = this.items.length - 1; each >= 0; each--) {
      this.items[each].onTouchGesture(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  file_dropped(event) {
    for (var each = this.items.length - 1; each >= 0; each--) {
      this.items[each].file_dropped(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  drag_file(event) {
    event.isDrag = true;
    for (var each = this.items.length - 1; each >= 0; each--) {
      this.items[each].drag_file(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
}
