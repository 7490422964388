import { AudioListenerScope } from "../audio_visual/audio/AudioListenerScope";
import { AudioAmbianceSet } from "../audio_visual/audio/audioAmbianceSet";
import { AmbianceAudioListener } from "../audio_visual/audio/audioListener";
import { InteractiveEvent } from "./InteractiveEvent";
import { DragDropEvent } from "./DragDropEvent";
import { VisualElements } from '../audio_visual/visual/VisualElements';

let c2 = require("c2.js");

export class CommonScenes {
  static UXSceneName = "ux-scene";
  static UXSceneGraphName = "ux";
}
export class SceneChangeRequest {
  isPushNavigationHistory;
  location;
  //replace_location;
  error;
  loading_promise;

  constructor(location, isPushNavigationHistory = true) {
    this.location = location;
    this.isPushNavigationHistory = isPushNavigationHistory;
  }
}

export class SceneGraphNode {
  active_scene;
  ux_scene;

  scenes;
  simulation;
  listener;
  visual_elements;
  event_scene_change_request_complete;
  change_request_in_progress;
  ux_scene_loading_promise;

  get firstScene() {
    return this.scenes[0];
  }
  get audio() {
    return this.simulation.audio;
  }
  get resources() {
    return this.simulation.resources;
  }
  get icanvas() {
    return this.simulation.icanvas;
  }
  get visualElements() {
    return this.visual_elements;
  }
  get server() {
    return this.simulation.server;
  }
  get server_file_cache() {
    return this.server.server_file_cache;
  }
  get sceneGraphSet() {
    return this.simulation.sceneGraphSet;
  }

  constructor(simulation) {
    this.scenes = [];
    this.simulation = simulation;
    this.visual_elements = new VisualElements(this);
  }

  async initiaize() {
    var a = this.audio;
    this.listener = new AmbianceAudioListener(a);
    this.audio.onStartAudioOnGesture = () => {
      this.onStartAudioOnGesture();
    };

    this.ux_scene_loading_promise = this.sceneGraphSet.fetchSceneGraphPromise(CommonScenes.UXSceneGraphName);
    this.ux_scene_loading_promise = this.ux_scene_loading_promise.then((result) => {
      this.ux_scene_loading_promise = undefined;
      return result;
    });
  }

  // initiaizeEnd() {
  //   //this.listener.start();
  //   // this.sceneGraphNode.invalidate();
  // }

  onStartAudioOnGesture() {
    this.listener?.onStartAudioOnGesture();
  }
  getUXScene() {
    return this.ux_scene;
  }
  getActiveScene() {
    for (let index = 0; index < this.scenes.length; index++) {
      const element = this.scenes[index];
      if (element.name == CommonScenes.UXSceneName) {
        continue;
      }
      return element;
    }
    return undefined;
  }

  startSceneChange(request) {


    if (this.change_request_in_progress) {
      console.warn("startSceneChange ignored: startSceneChange in progress");
      return;
    }

    this.simulation.increment_item_set();
    var name = request.location.sceneGraphName;
    if (this.sceneGraphSet.isSceneGraphFetched(name)) {
      this.performSceneChange(request);
    } else {
      this.change_request_in_progress = request;
      request.loading_promise = this.sceneGraphSet.fetchSceneGraphPromise(name);
      request.loading_promise = request.loading_promise.then((result) => {
        this.change_request_in_progress = undefined;
        this.performSceneChange(request);
        return result;
      });
    }
  }

  performSceneChange(request) {
    if (this.ux_scene_loading_promise) {
      console.warn("warning ux scene graph not yet loaded");
      this.ux_scene_loading_promise = this.ux_scene_loading_promise.then((result) => {
        this.performSceneChange(request);
        return result;
      });
      return;
    }

    var name = request.location.sceneGraphName;

    var found = this.sceneGraphSet.getSceneGraph(name);

    if (found == undefined) {
      request.error = `scene graph not found ${name}`;
      console.error(request.error);
      this.event_scene_change_request_complete?.(request);
      return;
    }

    if (request.location.sceneName == undefined) {
      request.location.sceneName = found.defaultOrFirstScene.name;
    }

    var found_scene = found.findSceneByName(request.location.sceneName)

    if (found_scene == undefined) {
      request.error = `scene not found ${request.location.sceneName}`;
      console.error(request.error);
      this.event_scene_change_request_complete?.(request);
      return;
    }

    if (this.ux_scene == undefined) {
      var ux = this.sceneGraphSet.getSceneGraph(CommonScenes.UXSceneGraphName);

      if (ux) {
        this.ux_scene = ux.defaultOrFirstScene;
        this.ux_scene.scene_graph_node = this;
        this.ux_scene.start(undefined);
      } else {
        // request.error = `ux scene not found`;
        console.warn(`ux scene not found`);
      }
    }

    if (this.active_scene) {
      this.active_scene.stop(found_scene);
      this.active_scene.scene_graph_node = undefined;
      var previous_active_scene = this.active_scene;
      this.active_scene = undefined;
    }

    this.active_scene = found_scene;
    this.active_scene.scene_graph_node = this;
    this.active_scene.start(previous_active_scene);

    this.scenes = [this.active_scene, this.ux_scene];
    this.event_scene_change_request_complete?.(request);
    this.invalidate();
  }

  invalidate() {
    // this.scenes.sort((a, b) => (a.sceneLayerOrder > b.sceneLayerOrder ? 1 : -1));

    // if (found_old != undefined || found_new != undefined) {
    // this.refreshAudio();
    this.icanvas.invalidate();
    // }
    this.icanvas.try_invalidated_draw();
  }

  findSceneByPath(path) {
    return this.findSceneByName(path.sceneName);
  }
  findSceneIndexByPath(path) {
    if (!path) {
      return undefined;
    }
    for (let index = 0; index < this.scenes.length; index++) {
      const element = this.scenes[index];
      if (element.name == path.sceneName) {
        return index;
      }
    }
  }
  findSceneByName(name) {
    for (const scene of this.scenes) {
      if (scene.name == name) {
        return scene;
      }
    }
  }

  drawFrame(icanvas) {
    for (let each in this.scenes) {
      let i = this.scenes[each];
      i.drawFrame(icanvas);
    }
  }

  mousedown(icanvas, e) {
    let event = new InteractiveEvent(icanvas, e);
    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].mousedown(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }

  mouseup(icanvas, e) {
    let event = new InteractiveEvent(icanvas, e);
    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].mouseup(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }

  mousemove(icanvas, e) {
    let event = new InteractiveEvent(icanvas, e);
    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].mousemove(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  keydown(icanvas, ievent) {

    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].keydown(ievent);
      if (ievent.isStopPropagation) {
        break;
      }
    }
  }
  keyup(icanvas, ievent) {

    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].keyup(ievent);
      if (ievent.isStopPropagation) {
        break;
      }
    }
  }
  onTouchTap(e) {
    let event = new InteractiveEvent(undefined, e);
    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].onTouchTap(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  onTouchPan(e) {
    let event = new InteractiveEvent(undefined, e);
    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].onTouchPan(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  onTouchSwipe(e) {
    let event = new InteractiveEvent(undefined, e);
    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].onTouchSwipe(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  onTouchDistance(e) {
    let event = new InteractiveEvent(undefined, e);
    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].onTouchDistance(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  onTouchRotate(e) {
    let event = new InteractiveEvent(undefined, e);
    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].onTouchRotate(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  onTouchGesture(e) {
    let event = new InteractiveEvent(undefined, e);
    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].onTouchGesture(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  file_dropped(e, files) {
    let event = new DragDropEvent(this.scene.icanvas, e, files);
    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].file_dropped(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  drag_file(e, files) {
    let event = new DragDropEvent(this.scene.icanvas, e, files);
    event.isDrag = true;
    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].drag_file(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }

  activate_event(event) {
    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].activate_event(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }

  activate(value, value_context) {
    let event = new InteractiveEvent();
    event.activate_value = value;
    event.activate_value_context = value_context;
    this.activate_event(event);
  }

  selectNextSceneInteractive(increment = 1) {
    var selectable = [];

    for (const each_scene of this.scenes) {
      var scene_selectable = each_scene.sceneInteractiveLayerInteractives.interactives.filter(item => item.isSelectable());
      selectable.push(...scene_selectable);
    }

    var index = selectable.findIndex(item => item.isSelected);

    if (index == -1) {
      index = selectable.findIndex(item => item.json.isDefaultSelection);
      if (index != -1) {
        index -= increment;
      }
    }
    else {

      selectable[index].setSelected(false);
    }

    index += increment;
    index = index % selectable.length;

    if (index < 0) {
      index += selectable.length;
    }

    //this.changeSelected(selectable[index]);

    selectable[index].setSelected(true);
  }

  getSelectedSceneInteractive() {

    for (const each_scene of this.scenes) {
      for (let each of each_scene.sceneInteractiveLayerInteractives.interactives) {
        if (each.isSelected) {
          return each;
        }
      }
    }

    return undefined;
  }
  selectNone() {

    for (const each_scene of this.scenes) {
      for (let each of each_scene.sceneInteractiveLayerInteractives.interactives) {
        if (each.isSelected) {
          each.setSelected(false);
        }
      }
    }
  }

}


// TryReplaceScene(scene_graph_set, old_path, new_path) {
//   var found_old = this.findSceneIndexByPath(old_path);

//   if (found_old == undefined) {
//     console.error(`scene path not found in scene graph node ${JSON.stringify(found_old)}`);
//     return false;
//   }

//   var found_new = new_path && scene_graph_set.getSceneGraphSceneByPath(new_path);

//   if (found_new == undefined) {
//     console.warn(`scene path not found in scene graph set ${JSON.stringify(new_path)}`);
//     return false;
//   }

//   var scene = this.scenes[found_old];
//   scene.stop(found_new);
//   scene.scene_graph_node = undefined;

//   found_new.scene_graph_node = this;
//   found_new.start(scene);

//   //if (found_old != undefined) {
//   this.scenes[found_old] = found_new;

//   //this.listener.scopeChanged(AudioListenerScope.fromScenePath(new_path), found_new.sceneGraph.audioAmbiance);

//   return true;
//   // } else {
//   //   this.scenes.push(found_new);
//   // }
//   // } else {
//   //   console.warn(`scene path not found ${JSON.stringify(new_path)}`);

//   //   if (found_old != undefined) {
//   //     this.scenes.splice(found_old, 1);
//   //   }
//   // }
// }

// addAndStart(scene, isSetScope = false) {
//   scene.scene_graph_node = this;
//   scene.start(undefined);
//   this.scenes.push(scene);
//   this.scenes.sort((a, b) => (a.sceneLayerOrder > b.sceneLayerOrder ? 1 : -1));

//   // this.listener.scopeChanged(
//   //   isSetScope ? scene.toScenePath().toAudioScope() : undefined,
//   //   scene.sceneGraph.audioAmbiance
//   // );
// }

// tryAddAndStartScenePath(scene_graph_set, path, isSetScope = false) {
//   var found = path && scene_graph_set.getSceneGraphSceneByPath(path);

//   if (found == undefined) {
//     console.warn(`scene path not found in scene graph set ${JSON.stringify(path)}`);
//     return false;
//   }

//   this.addAndStart(found, isSetScope);
//   return true;
// }


// get audioAmbianceSet() {
//   return this.audioAmbianceSet_cache;
// }
// set audioAmbianceSet(value) {
//   if (this.audioAmbianceSet_cache) {
//     this.audioAmbianceSet_cache.stop();
//   }
//   this.audioAmbianceSet_cache = value;

//   this.audioAmbianceSet_cache?.start(this);
// }

// refreshAudio() {
//   this.audio.leaveRegion("");
//   var audio_set = new AudioAmbianceSet(this.audio);

//   this.scenes.forEach((eachScene) => {
//     for (const { node, attributes } of eachScene.sceneGraph.graph.nodeEntries()) {
//       var eachGraphScene = attributes.scene;
//       let eachAmbiance = eachGraphScene.audioAmbiance;

//       if (eachAmbiance) {
//         audio_set.addAmbiance(eachAmbiance);
//       }
//     }
//   });
//   this.audio.enterRegion("", audio_set);
// }