import { InteractivePlatformCanvas } from "./InteractivePlatformCanvas.js";
//import { VideoFileResource } from './resources/VideoFileResource.js';
//import { VisualElements } from './sceneGraph/VisualElements.js';
import { WebApplication } from '../webApplication.js';


export class InteractivePCCanvas extends InteractivePlatformCanvas {
  constructor() {
    super();

  }

  isPlatform() {
    return InteractivePCCanvas.isPC();
  }

  isTouchCapable() {
    return super.isTouchCapable();
  }

  uninitialize() {
  }

  initialize(interactiveCanvas) {
    super.initialize(interactiveCanvas);
    this.name = "PC";
  }

  static isPC() {
    return true;
  }


  configure_visual_elements() {
  }
  apply_to_default_settings() {
    super.apply_to_default_settings();
   // this.interactiveCanvas.state.application.setDefaultSetting(WebApplication.isShowConsoleLogOverlaySettingName, false);
    //this.interactiveCanvas.state.application.setDefaultSetting(WebApplication.isShowMemoryDiagnosticsOverlaySettingName, false);
    this.interactiveCanvas.state.application.setDefaultSetting(WebApplication.IsSBVideoEnabledSettingName, false);
    this.interactiveCanvas.state.application.setDefaultSetting(WebApplication.IsPSVideoEnabledSettingName, true);
  }
}
