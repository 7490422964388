export class ValueChangedSubscriptions {
	static addToObjectArrayPropertyByName(obj, name, item) {
		var value = obj[name];

		if (value == undefined) {
			value = obj[name] = new ValueChangedSubscriptions(name);
		} else {
		}




		value.elements.push(item);
	}
	static removeFromObjectArrayPropertyByName(obj, name, item) {
		var value = obj[name];
		value?.removeElement(item);
	}
	static updateValueByName(obj, name, value) {
		var subscription = obj[name];
		subscription?.updateValue(value);

	}
	name;
	elements;

	constructor(name) {
		this.name = name;
		this.elements = [];
	}

	removeElement(element) {
		let index = this.elements.indexOf(element);
		if (index >= 0) {
			this.elements.splice(index, 1);
		}
	}
	get isEmpty() {
		return this.elements.length == 0;
	}

	updateValue(value) {
		for (const each of this.elements) {
			each(value);
		}
	}
}
