/* context
https://en.wiktionary.org/wiki/economy

*/

export class TokenRewardsFromDataContext {
  data_context;
  constructor(data_context) {
    this.data_context = data_context;
  }

  get tokens_per_second() {
    if (this.data_context?.tokens_per_minuite) {
      return this.data_context.tokens_per_minuite / 60.0;
    }
    if (this.data_context?.tokens_per_second) {
      return this.data_context.tokens_per_second;
    }
    return 0;
  }

  get tokens_per_win() {
    if (this.data_context?.tokens_per_win) {
      return this.data_context.tokens_per_win;
    }
    return 0;
  }

  get tokens_per_draw() {
    if (this.data_context?.tokens_per_draw) {
      return this.data_context.tokens_per_draw;
    }
    return 0;
  }
  get tokens_per_loss() {
    if (this.data_context?.tokens_per_loss) {
      return this.data_context.tokens_per_loss;
    }
    return 0;
  }
}
