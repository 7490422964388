import { CustomerAccount } from "./customerAccount.js";
import Bowser from "bowser";
import { StackLayout } from './view/stackLayout.js';
import { ReadonlyProperty } from './view/propertyEditor.js';

export class WebBrowserClient {
  resources;
  application;
  json;
  server;
  browser;

  constructor(resources, application, server) {
    this.resources = resources;
    this.application = application;
    this.server = server;
    this.browser = Bowser.getParser(window.navigator.userAgent);
 
  }

  stroageItemName() {
    return this.resources.combineJsonResourceName(this.application.name, "browser.storage");
  }

  initialize() {
    this.json = this.resources.getLocalStorageJsonItem(this.stroageItemName());
  }
  saveState() {
    this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
  }
  shutdown() {
    this.saveState();
  }


  collectEditableProperties(layout) {
    let name_property = Object.assign(new ReadonlyProperty(),{
      name:"name",
      getValue : () => {
        return this.browser.getBrowserName();
      }
    });
    layout.addAsTableRow(name_property.getEditorElements());

    let version_property = Object.assign(new ReadonlyProperty(),{
      name:"version",
      getValue : () => {
        return this.browser.getBrowserVersion();
      }
    });
    layout.addAsTableRow(version_property.getEditorElements());
  }
  getAuthorInterfaceName(){
    return "Browser";
  }
  createAuthorInterfaceElement() {      
    let layout = new StackLayout();
    this.collectEditableProperties(layout);
    return layout.element;
  }
  addAuthorInterfaceElementToTreeview(treeview) {
    let elm = this.createAuthorInterfaceElement();
    treeview.addItem(this.getAuthorInterfaceName(), elm, true);
  }
}
