
import { SizeType, parseSizeType } from './resources/SizeType';
import { WebApplication } from '../../webApplication';

export class ResourceCanvasElement {
    vis;
    visual_resource;
    canvasElement;
    after_loading;
    isHidden;
    onDrawing;
    // onError;
    isError;

    constructor(vis, visual_resource = undefined) {
        this.vis = vis;
        this.visual_resource = visual_resource;

        if (vis.scene.application.getSetting(WebApplication.IsVerboseLoggingSettingName)) {
            //console.log("new:ResourceCanvasElement")
        }
    }
    get url_file_info(){
        return this.visual_resource?.url_file_info;
    }
    get isLoadingSuccess() {

        if (this.visual_resource == undefined) {
            return false;
        }
        if (this.visual_resource.isLoaded != true) {
            return false;
        }
        this.visual_resource.checkForErrors();
        if (this.visual_resource.isError == true) {
            return false;
        }
        return true;
    }

    toSourceURLNameExt() {
        return this.visual_resource.resource_request.toURLNameAndExtension();
    }
    toSourceURLName() {
        return this.visual_resource.toSourceURLName();
    }
    toSourceURL() {
        return this.canvasElement.toSourceURL();
    }
    get url() {
        return this.toSourceURL();
    }

    start_loading() {

        if (this.visual_resource == undefined) {
            this.onVisualLoaded();
            return;
        }

        if (this.vis.obj.geometry) {

            if (this.vis.obj.json.visual_resource_sizeType != undefined) {
                this.visual_resource.sizeType = parseSizeType(this.vis.obj.json.visual_resource_sizeType);
            } else {
                this.visual_resource.sizeType = SizeType.ScaleToFitRectangle;
            }
            this.visual_resource.getRectangle = () => this.vis.obj.geometry.get_absolute_rect_shape();
        }

        this.visual_resource.onVisualLoaded = (s) => this.onVisualLoaded();
        this.visual_resource.start_loading(this.vis.server_file_cache, this);
    }

    onVisualLoaded() {

        this.after_loading?.();

        this.canvasElement?.autoPlay?.();
    }

    canvasElement_on_drawing(params) {
        this.onDrawing?.(params);
    }


    start() {

        this.canvasElement = this.visual_resource.getCanvasElement();
        this.canvasElement.onDrawing=this.canvasElement_on_drawing.bind(this);

        this.canvasElement.onError = (elm, err) => this.onElementError(elm, err);

        this.canvasElement.setHidden(this.isHidden);
        this.canvasElement.draw_order = this.vis.obj.scene.draw_order
        //this.canvasElement.invalidate();
        // this.canvasElement.waitOn(this.interactive.scene.canvasElement);

        this.vis.resource_displayed(this.visual_resource, true);
        this.visual_resource.start(this);

        this.vis.icanvas.addElement(this.canvasElement);
    }
    onElementError(elm, err) {

        var pause_error = "The play() request was interrupted by a call to pause()"
        if (err.includes(pause_error)) {
            return;
        }

        this.visual_resource.notifyError();

        this.isError = true;
        this.vis.onResourceCanvasElementError(this);
        //    this.onError?.(elm, err);
    }
    restart() {
        if (this.canvasElement) {
            this.canvasElement.setHidden(this.isHidden);
            this.vis.icanvas.addElement(this.canvasElement);
            this.vis.resource_displayed(this.visual_resource, true);
        }
        else {
            this.start();
        }
    }
    stop(next_resource_canvas_element) {

        if (this.visual_resource.isLoading()) {
            this.visual_resource.cancelLoading();
            this.isCanceled = true;
        } else {
            this.visual_resource.stop();
        }

        this.removeCanvasElement();
    }
    collectCanvasElements(result) {
        if (this.canvasElement) {
            result.push(this.canvasElement);
        }
    }
    setHidden(value) {
        this.canvasElement?.setHidden(value);
        this.isHidden = value;
    }
    setDrawOrder(value) {
        this.canvasElement?.setDrawOrder(value);
        this.isHidden = value;

    }

    removeCanvasElement() {
        if (this.canvasElement) {
            this.vis.icanvas.removeElement(this.canvasElement);
        }
    }
    isLoading() {
        return this.visual_resource?.isLoading();
    }
    toRect(scaleToCanvas = true) {
        return this.visual_resource?.toRect(scaleToCanvas ? this.vis.icanvas : undefined);
    }


    // onResourceCanvasElementError(visual_resource) {
    //     this.isError = true;
    //    // this.visual_resource.notifyError();
    //     this.vis.onResourceCanvasElementError(this);
    // }
}
