import { StackLayout } from "../../view/stackLayout";
import { NullConnectedStems } from './NullConnectedStems';


export class NullWebAudio {
  connectedStems;
  constructor(resources, account) {
    this.connectedStems= new NullConnectedStems();
  }

  connectNodeToLayer(layer, node) {
  }

  initialize() {
    console.log("No Audio") ;
  }

  tryStartAudio() {
  }

  uninitialize() { }

  start() { }

  deactivate() {
  }

  reactivate() {
  }

  stroageItemName() {
  }

  saveState() {
  }

  shutdown() {
  }

  playSoundEffects(stems) {
  }

  startAudioOnGesture() {
  }


  drawFrame(icanvas) { }
  mousedown(icanvas, e) {
    this.startAudioOnGesture();
  }
  mouseup(icanvas, e) { }
  mousemove(icanvas, e) { }
  keydown(icanvas, e) {
    this.startAudioOnGesture();
  }
  onTouchTap(e) {
    this.startAudioOnGesture();
  }
  onTouchPan(e) { }
  onTouchSwipe(e) {
    this.startAudioOnGesture();
  }
  onTouchDistance(e) { }
  onTouchRotate(e) { }
  onTouchGesture(e) { }

  collectEditableProperties(layout) {
  }

  getAuthorInterfaceName() {
    return "audio";
  }
  createAuthorInterfaceElement() {
    let layout = new StackLayout();
    this.collectEditableProperties(layout);
    return layout.element;
  }
}
