import { DownloadDiagnostics } from './DownloadDiagnostics';
import { DebugOverlay } from './sceneAuthorInterface/DebugOverlay';

export class MemoryDiagnostics {
  item_set_count = 0;
  item_count = 0;
  total_bytes_downloaded;
  elements = {};
  memory_enabled = false;
  diagnostics_container;
  application;
  constructor(application) {
    this.application=application;
    this.downloads = [];
    this.maxDownloadHistory = 8;
    this.downloadElements = {};
    this.total_bytes_downloaded = 0;
  }


  setupElements(application) {

    this.memory_enabled = true;

    this.diagnostics_container = document.getElementById(DebugOverlay.diagnostics_container_name); // Get the container element

    if (this.diagnostics_container == undefined) {
      return;
    }

    var element = document.createElement('p');
    element.style.margin = '1px';
    element.classList.add('diagnostics-item');
    this.diagnostics_container.appendChild(element);
    this.elements['device_memory'] = element;

    element = document.createElement('p');
    element.style.margin = '1px';
    element.classList.add('diagnostics-item');
    this.diagnostics_container.appendChild(element);
    this.elements['application_memory'] = element;
  }

  cleanupElements(application) {
    this.memory_enabled = false;


    Object.keys(this.elements).forEach(key => {
      var value = this.elements[key];
      //console.log(key + ': ' + );
      this.diagnostics_container.removeChild(value);
    });

    // for (const [key, value] of this.elements) {
    //   this.diagnostics_container.removeChild(value);
    //}
    this.diagnostics_container = undefined;
  }

  update() {

    if (!this.memory_enabled) {
      return;
    }
    this.drawDeviceDiagnostics();
    this.drawBrowserDiagnostics();

  }
  drawDeviceDiagnostics() {

    var element = this.elements['device_memory'];

    if (!element) {
      return;
    }

    var memory = -1;
    var browserMemory_gb = -1;

    try {
      if (performance.memory) {
        memory = navigator.deviceMemory.toFixed(1);
        browserMemory_gb = (performance.memory.jsHeapSizeLimit / (1024 * 1024 * 1024)).toFixed(1);

      }
    } catch (error) {
      console.warn(error);
    }

    var system_message = `Device Memory: ${browserMemory_gb}GiB of ${memory}GiB total`;

    element.textContent = system_message;
    // this.draw_text(system_message, { x: position.x, y: position.y }, 20);

  }

  drawBrowserDiagnostics() {
    var element = this.elements['application_memory'];

    if (!element) {
      return;
    }
    var browserMemory = {
      jsHeapSizeLimit: -1,
      totalJSHeapSize: -1,
      usedJSHeapSize: -1
    };

    try {
      if (performance.memory) {
        browserMemory = performance.memory;

        for (let i = 0; i < browserMemory.length; i++) { browserMemory[i] = Math.round(browserMemory[i] * 0.000001); }

      }
    } catch (error) {
      console.warn(error);
    }

    var heap_size = (Math.round(browserMemory.totalJSHeapSize * 0.000001 * 1000) / 1000).toFixed(3);

    var used_heap = (Math.round(browserMemory.usedJSHeapSize * 0.000001 * 1000) / 1000).toFixed(3);
    var browser_message = `Heap Memory: ${used_heap}MB / ${heap_size}MB`;

    element.textContent = browser_message
  }

  increment_item_set() {
    this.item_set_count += 1;
    this.item_count = 0;
  }
  increment_item() {
    this.item_count += 1;
  }

  get kilobytes_downloaded() {
    return this.total_bytes_downloaded / 1024;
  }

  get megabytes_downloaded() {
    return this.total_bytes_downloaded / (1024 * 1024);
  }

  on_download_changed() {

    // if (!this.memory_enabled) {
    //   return;
    // }

    const downloadsContainer = document.getElementById('downloads-container'); // Get the container element
    //downloadsContainer.innerHTML = ''; // Clear existing content
    if (!downloadsContainer) {
      return;
    }
    let totalDownloadElement = this.downloadElements['total'];
    if (!totalDownloadElement) {
      totalDownloadElement = document.createElement('p');
      totalDownloadElement.style.margin = '1px';
      totalDownloadElement.classList.add('downloads-item');
      downloadsContainer.appendChild(totalDownloadElement);
      this.downloadElements['total'] = totalDownloadElement;
    }
    totalDownloadElement.textContent = `Download Total: ${parseFloat(this.kilobytes_downloaded.toFixed(2)).toLocaleString()} KB`;

    const existingDownloadNames = new Set();

    this.downloads.forEach((download, index) => {
      existingDownloadNames.add(download.name);

      let downloadElement = this.downloadElements[download.name];
      if (!downloadElement) {
        downloadElement = document.createElement('p');
        downloadElement.style.margin = '1px';
        downloadElement.classList.add('downloads-item');
        if (index === 0) {
          downloadsContainer.insertBefore(downloadElement, downloadsContainer.firstChild);
        } else {
          const previousDownload = this.downloads[index - 1];
          const previousElement = this.downloadElements[previousDownload.name];
          downloadsContainer.insertBefore(downloadElement, previousElement.nextSibling);
        }
        // downloadsContainer.appendChild(downloadElement);
        this.downloadElements[download.name] = downloadElement;
      }

      // const downloadElement = document.createElement('p');
      // downloadElement.classList.add('download-info'); // Add the class    // Format download information:
      let downloadText = `${download.item_set_count}-${download.item_count} ${download.type} - ${download.name}: ${parseFloat(download.kilobytes.toFixed(2)).toLocaleString()} KB`;

      if (download.totalBytes > 0) {
        downloadText += ` / ${parseFloat(download.kilobytes.toFixed(2)).toLocaleString()} KB`;
      }

      // if (download.startTime) {
      //   const startTimeFormatted = new Date(download.startTime).toLocaleTimeString();
      //   downloadText += ` (Started: ${startTimeFormatted})`;
      // }

      if (download.finished) {
        downloadText += ' - Finished';
      } else if (download.started) {
        downloadText += ' - In Progress';
      }

      downloadElement.textContent = downloadText;
      //  downloadsContainer.appendChild(downloadElement); // Add to the container
    });

    downloadsContainer.prepend(totalDownloadElement);
    for (const downloadName in this.downloadElements) {
      if (downloadName !== 'total' && !this.downloads.find(d => d.name === downloadName)) {
        downloadsContainer.removeChild(this.downloadElements[downloadName]);
        delete this.downloadElements[downloadName];
      }
    }
  }
  start_download(type, resource_request, mediaElement, startTime = Date.now()) {

    //if (!this.memory_enabled) {
    //   return;
    // }
    // let oldestFinishedIndex = this.downloads.findIndex(download => download.finished);

    // if (this.downloads.length >= this.maxDownloadHistory && oldestFinishedIndex > -1) {
    //   const oldestDownload = this.downloads.splice(oldestFinishedIndex, 1)[0];
    //   oldestDownload.removeEventHandlers();
    // }
    if (this.downloads.length >= this.maxDownloadHistory) {
      const oldestDownload = this.downloads.pop(); // Remove the last item
      oldestDownload.removeEventHandlers();
    }

    const download = new DownloadDiagnostics(type, resource_request, startTime, this);
    download.setupEventHandlers(mediaElement);
    download.item_set_count = this.item_set_count;
    download.item_count = this.item_count;
    this.item_count += 1;
    this.downloads.unshift(download);
    return download;
  }
  start_fetch_download(type, resource_request, total_size_bytes, startTime = Date.now()) {

    //if (!this.memory_enabled) {
    //   return;
    // }
    // let oldestFinishedIndex = this.downloads.findIndex(download => download.finished);

    // if (this.downloads.length >= this.maxDownloadHistory && oldestFinishedIndex > -1) {
    //   const oldestDownload = this.downloads.splice(oldestFinishedIndex, 1)[0];
    //   oldestDownload.removeEventHandlers();
    // }
    if (this.downloads.length >= this.maxDownloadHistory) {
      const oldestDownload = this.downloads.pop(); // Remove the last item
      oldestDownload.removeEventHandlers();
    }

    const download = new DownloadDiagnostics(type, resource_request, startTime, this);
    download.totalBytes=total_size_bytes;
    //download.setupEventHandlers(mediaElement);
    download.item_set_count = this.item_set_count;
    download.item_count = this.item_count;
    this.item_count += 1;
    this.downloads.unshift(download);

    download.handleLoadStart();
    return download;
  }
}