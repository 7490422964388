import { startClient } from "./../../cabin-library/client/src/main.js";
import { WebApplication } from "./../../cabin-library/client/src/webApplication.js";
import { LakesideCabinServer } from "./../../cabin-library/client/src/LakesideCabinServer.js";
import { WebApplicationState } from "./../../cabin-library/client/src/WebApplicationState.js";
import { ExternalModules } from './../../cabin-library/client/src/modules/ExternalModules.js';

import { ChessExternalModule } from './externalModules/modules/chess.js';
import { SolitaireExternalModule } from './externalModules/modules/solitaire.js';
import { CardCraftExternalModule } from './externalModules/modules/card_craft.js';

let versionNumber = "0.6.3d";

class LakesideCabinWebApplication extends WebApplication {
  constructor(state) {
    super(state.resources, "Lakeside Cabin", state.clientVersionNumber);
  }

  initializeExternalModules(state) {
    ExternalModules.modules = [
      new ChessExternalModule(),
      new SolitaireExternalModule(), 
      new CardCraftExternalModule()
	]
  }
}

class LakesideCabinWebApplicationServer extends LakesideCabinServer {
  constructor(state) {
    super(state.resources, state.application);
  }
}

(async () => {
  let state = new WebApplicationState();
  state.clientVersionNumber = versionNumber;

  let newWebApplication = (withState) => {
    return new LakesideCabinWebApplication(withState);
  };

  let newServer = (withState) => {
    return new LakesideCabinWebApplicationServer(withState);
  };

  await startClient(state, newWebApplication, newServer);
})();