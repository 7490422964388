
let geometry_js = require("../geometry.js");
import { DrawScope } from "../audio_visual/DrawScope.js";
//import { SizeType } from "../audio_visual/visual/SizeType.js";
import { VisualElement } from "../audio_visual/visual/visualElement.js";
import { GeometryElements } from '../geometry/GeometryElements.js';
import { SizeType } from '../audio_visual/visual/resources/SizeType.js';

export class InteractiveArea {
  static typename = "interactive.area";
  interactive;
  geometry;
  json;

  //visual_resource;
  visual_element;
  //canvasElement;
  //on_visual_loaded;

  get resources() {
    return this.interactive.resources;
  }
  get icanvas() {
    return this.interactive.icanvas;
  }

  get scene() {
    return this.interactive.scene;
  }
  get visualElements() {
    return this.scene.visualElements;
  }
  get application() {
    return this.scene.application;
  }
  get geometry_isRelativeTo() {
    return this.scene;
  }
  constructor(interactive, json) {
    this.interactive = interactive;
    this.json = json;

    this.visual_element = new VisualElement(this);
  }

  setImagePath(path) {
    this.json.image = path;

    this.visual_element.stop();
    this.visual_element = new VisualElement(this);
    this.visual_element.start();
  }


  drawFrame(icanvas) {

    this.visual_element.drawFrame(icanvas);

    this.geometry.border_size = 2;
    var outline_scope = DrawScope.Debug;

    if (this.scene.simulation.selectionMode.isSceneInteractionMode()) {
      if (this.interactive.isSelected && this.interactive.point_of_interest == undefined) {

        var ux_scene = this.scene.simulation.getUXScene();
        var ux_scene_interactive_layer = ux_scene.sceneInteractiveLayer;

        if (!ux_scene_interactive_layer.isFadedOut) {

          outline_scope = DrawScope.Normal
          this.geometry.border_size = 5;
        }

      }
    }

    this.geometry.draw(icanvas, outline_scope);
  }

  mousedown(ievent) { }

  isIEventOnInteractiveArea(ievent) {
    let mouse_point = geometry_js.mouseEventToPoint(ievent.e);
    return this.geometry.containsPoint(mouse_point);
  }
  getScreenSpaceAreaRect() {
    return this.geometry.get_absolute_rect_shape();
  }

  mouseup(ievent) {
    if (this.isIEventOnInteractiveArea(ievent)) {
      this.interactive.onMouseUp(ievent, this);
    }
  }
  onTouchTap(ievent) {
    if (this.isIEventOnInteractiveArea(ievent)) {
      this.interactive.onMouseUp(ievent, this);
    }
  }

  mousemove(ievent) { }

  isSelectable() {
    return this.geometry != undefined && this.geometry.scale != 0 && this.json.isSelectable != false;
  }
  start() {
    this.geometry = GeometryElements.createGeometryFromObjectWithJson(this);
    this.visual_element.start();
  }

  stop() {
    this.visual_element.stop();
  }

  getResourcePath() {
    return this.json.resourcePath || this.interactive.getResourcePath();
  }

  collectCanvasElements(result) {
    this.visual_element.collectCanvasElements(result);
  }
  setAreaReletivePosition(point) {
    if (this.geometry) {
      this.geometry.setPosition(point);
      this.icanvas.invaidate();
    }
  }

  toRect(scaleToCanvas = false) {

    return this.getScreenSpaceAreaRect();
  }
}
