import { VideoFileToElement } from '../VideoFileToElement.js';
import { SingleBufferedSharedVideoResourceCanvasElement } from '../SingleBufferedSharedVideoResourceCanvasElement.js';
import { FileResourceRequest } from '../../../resources/FileResourceRequest.js';
import { SizeType } from './SizeType.js';
import { FileResource } from '../../../resources/FileResource.js';
let c2 = require("c2.js");

export class VideoFileSingleBufferedResource extends FileResource {

  static requestMp4VideoResource(request) {
    return new VideoFileSingleBufferedResource(request);
  }
  static disposeMp4VideoResource(resource) { }

  static canCreateResourceFromJson(json, property, webapp) {
    return property == "video" && json[property] !== undefined && VideoFileToElement.isEnabled(webapp);

  }
  static requestResource(name, path, path2, webapp) {
    let request = new FileResourceRequest(path, name, "", "", path2, true, false, false, webapp);

    request.setExtension(".mp4");
    request.setCategoryPath("visuals/video/");
    request.updateFullpath();

    var file_entries = webapp.server.server_file_cache.get_additional_manifest_entries_for_file_request(request);
    if (file_entries?.length > 0) {

      var names = file_entries.map(each => FileResource.getFullpathFilenameWithoutExtension(each));
      var selection = webapp.platform_canvas.select_video_content(names);
      if (selection) {
        request.change_to_filename(selection);
      }
    }

    var resource = this.requestMp4VideoResource(request);

    if (resource) {
      resource.url = resource.resource_request.toUrlPath();
      resource.server_file_cache = webapp.server.server_file_cache;
      resource.url_file_info = resource.server_file_cache.server_asset_lookup[resource.url];
      resource.type = "video";
    }

    return resource;
  }
  static createResourceFromJson(json, property, path, path2, webapp) {
    if (this.canCreateResourceFromJson(json, property, webapp)) {
      let result = this.requestResource(json.video, path, path2, webapp);
      result.event_playedOnce = json["video.event.playedOnce"];
      result.isLoop = json["video.loop"];
      result.isMuted = json["video.muted"];
      result.isAutoPlay = json["video.autoplay"];

      return result;
    }
  }

  resource_element;
  resource_request;
  loading_promise;
  isError;
  isLoaded = true;

  resource_client_area;
  getRectangle;
  sizeType = SizeType.ScaleToFitScreen;

  event_playedOnce;
  isLoop;
  isMuted;
  isAutoPlay;
  onRunAction;
  onVisualLoaded;
  server_file_cache;

  response;
  //blob;
  //blob_url;
  //blob_file_info;

  download_diagnostics;

  constructor(resource_request) {
    super();
    this.resource_request = resource_request;
  }

  toSourceURL() {
    return this.resource_request.toUrlPath();
  }
  toSourceURLName() {
    return this.resource_request.name;
  }
  createCanvasElement(vis) {
    return new SingleBufferedSharedVideoResourceCanvasElement(vis, this);
  }

  unapply_property_values_to_resource_element() {
    this.resource_element.muted = true;
    this.resource_element.loop = true;
  }
  unapply_event_values_to_resource_element() {
    // this.resource_element.onended = undefined;
  }

  apply_property_values_to_resource_element() {


    // if (this.isAutoPlay !== undefined) {
    // } else {
    //   this.isAutoPlay = true;
    // }

    if (this.isMuted !== undefined) {
      this.resource_element.muted = this.isMuted == true;
    } else {
      this.resource_element.muted = true;
    }

    if (this.isLoop !== undefined) {
      this.resource_element.loop = this.isLoop == true;
    }
    else {
      this.resource_element.loop = true;
    }

    // if (this.event_playedOnce !== undefined) {
    //   this.resource_element.onended = () => {
    //     this.onRunAction?.(this.event_playedOnce);
    //   }
    // }
  }

  on_buffer_ended(index, buffer) {
    if (this.event_playedOnce !== undefined) {
      this.onRunAction?.(this.event_playedOnce);
    }
  }

  cancelLoading() {

  }

  start(buffer_video_element) {
    this.resource_element = buffer_video_element;
    var url = this.resource_request.toUrlPath();

    this.apply_property_values_to_resource_element();
    this.resource_element.src = url;

    this.resource_element.currentTime = 0;
  }

  notifyError() {
    this.isError = true;
  }
  stop() {
    if (this.isError) {

    }
  }

  start_loading(server_file_cache, resource_canvas_element) {
   
    this.blob_file_info = this.url_file_info;
    //if (this.resource_request.application.getSetting(WebApplication.IsVerboseLoggingSettingName)) {
    //console.info(`video:buffered:loading ${url}`);
    //}


    this.onVisualLoaded?.(self);

    // var element = resource_canvas_element.aquire_element_for_loading(this);

    // if(!element){
    //   this.isError=true;
    //   self.onVisualLoaded?.(self);
    //   return;
    // }

    // this.resource_request.application.memory_diagnostics.start_download("video",this.resource_request, this.resource_element);


    // this.server_file_cache = server_file_cache;
    // var url = this.resource_request.toUrlPath();

    // this.blob_file_info = this.server_file_cache.server_asset_lookup[url];
    // if (!this.blob_file_info) {
    //   console.warn(`missing video file in manifest: ${url}`);
    // }

    // console.info(`video:fetch ${url}`);
    // var size = this.blob_file_info.size;
    // this.download_diagnostics = this.resource_request.application.memory_diagnostics.start_fetch_download("video fetch", this.resource_request, size);


    // let self = this;


    // this.loading_promise = fetch(url)
    //   .then(response => {
    //     this.response = response;
    //     if (!response.ok) { // Check for HTTP errors (status codes outside 200-299)
    //       throw new Error(`Network response was not ok: ${response.status}`);
    //     }
    //     return response.blob()
    //   })
    //   .then(blob => {
    //     this.blob = blob;

    //     this.blob_url = URL.createObjectURL(blob);
    //     console.info(`video:fetched name=${this.toSourceURLName()}, bytes=${this.blob.size}, local_url=${this.blob_url}`);
    //     this.isLoaded = true;
    //     this.isError = false;
    //     this.loading_promise = undefined;
    //     this.download_diagnostics.handleFetchedData(this.blob.size);
    //     self.onVisualLoaded?.(self);
    //     return blob;
    //   })
    //   .catch(error => {
    //     this.isError = true;
    //     this.loading_promise = undefined;
    //     this.download_diagnostics.handleDataError();
    //     console.error(`error:video:fetch ${url}:`, error);
    //     // Handle the error gracefully:
    //     // - Display an error message to the user.
    //     // - Potentially try loading a fallback video.
    //     // - Prevent further execution if necessary.
    //   });
  }

  initialize() {

  }

  // start() {
  //   //this.resource_element.currentTime = 0;
  // }
  // stop() {

  //   // this.resource_element.currentTime = 0;
  // }
  isLoading() {
    return false;
  }

  getLoadingPromise() {
    return undefined;
  }

  checkForErrors() {

    // if (this.blob_url == undefined) {
    //   this.isError = true;
    //   return true;
    // }
    //this.isError=true;
    //return true;

    // if (this.resource_element.videoWidth == 0) {
    //   this.isError = true;
    //   return true;
    // }
    return false
  }

  // draw(icanvas) {
  //   icanvas.playing_video_resource(this);
  // }
  toRect(icanvas = undefined) {

    var video_width = this.blob_file_info.width;
    var video_height = this.blob_file_info.height

    if (icanvas) {
      var canvas_w = icanvas.get_width();
      var canvas_h = icanvas.get_height();

      if (video_width == 0 || video_height == 0) {
        video_width = canvas_w;
        video_height = canvas_h;
      }

      var hRatio = canvas_w / video_width;
      var vRatio = canvas_h / video_height;
      var ratio = Math.min(hRatio, vRatio);
      var result = new c2.Rect(0, 0, video_width * ratio, video_height * ratio);
      if (result.w == NaN) {

      }
      return result;
    }

    var result = new c2.Rect(0, 0, video_width, video_height);
    return result;
  }

  getCanvasElement() {
    // if (this.canvas_element == undefined) {
    //   this.canvas_element = new VideoCanvasElement(this, this.resource_element);
    // }
    return undefined;
    //return new VideoFileToElement(this, this.resource_element);
  }

  pixel_size() {
    var video_width = this.blob_file_info.width;
    var video_height = this.blob_file_info.height
    return [video_width, video_height];
  }


}
