import { ModuleActivationRewards } from "../externalModules/ModuleActivationRewards";

export class CustomerAccountTokenRewards extends ModuleActivationRewards {
  source_name;
  account;

  constructor(account, source_name) {
    super();
    this.account = account;
    this.source_name = source_name;
  }
  onStart(tokens_per_second) { }
  onDepositTokens(tokens_amount) { }
  onEnd() { }
}
