
export class DoubleBufferedSharedVideoResourceCanvasElement {
    buffer_video_element;
    buffer_video_index;
    visual_resource;
    vis;
    after_loading;
    //onError;
    isError = false;
    isCanceled = false;

    constructor(vis, visual_resource) {
        this.vis = vis;
        this.visual_resource = visual_resource;
        console.info("new:DoubleBufferedSharedVideoResourceCanvasElement");
    }
    get url_file_info(){
        return this.visual_resource?.url_file_info;
    }
    start_loading() {
        this.visual_resource.onVisualLoaded = (s) => this.onVisualLoaded();
        this.visual_resource.start_loading(this.vis.server_file_cache, this);
    }

    onVisualLoaded() {
        if (this.isCanceled) {
            return;
        }

        this.after_loading?.();
    }
    start() {
        this.isCanceled = false;
        var loaded = this.isLoadingSuccess;
        if (!loaded) {
            return false;
        }

        this.buffer_video_index = this.vis.icanvas.register_next_video_buffer(this);

        if (this.buffer_video_index == undefined) {
            console.info(`video buffer not avalaible to start ${this.visual_resource.toSourceURLName()}`);
            return false;
        }

        this.buffer_video_element = this.vis.icanvas.get_video_buffer(this.buffer_video_index);

        if (!this.buffer_video_element) {
            console.info(`missing video buffer: ${this.visual_resource.toSourceURLName()}`);

            return false;
        }

        let image_rect = this.visual_resource.toRect(this.vis.icanvas);
        this.buffer_video_element.width = image_rect.w;
        this.buffer_video_element.height = image_rect.h;

        this.visual_resource.start(this.buffer_video_element);

        this.vis.icanvas.swap_buffer_to_index(this.buffer_video_index);
        return true;
    }
    on_buffer_playing(index, buffer) {
        this.vis.resource_displayed(this.visual_resource,true);
    }
    on_buffer_error(index, buffer) {
        this.isError = true;
        this.visual_resource.notifyError();
        this.vis.onResourceCanvasElementError(this);
    }

    on_buffer_ended(index, buffer) {
        this.visual_resource.on_buffer_ended?.(index, buffer);
    }

    stop(next_resource_canvas_element) {

        if (this.visual_resource.isLoading()) {
            this.visual_resource.cancelLoading();
            this.isCanceled = true;
        } else {
            this.visual_resource.stop();
        }

        this.clearFromBuffer(next_resource_canvas_element);
    }

    clearFromBuffer(next_resource_canvas_element) {
        if (this.buffer_video_index == undefined) {
            return;
        }

        if (this.vis.icanvas.is_registered_video_buffer_index_as(this, this.buffer_video_index)) {

            this.visual_resource.unapply_event_values_to_resource_element();

            var isNextElementSameType = next_resource_canvas_element && next_resource_canvas_element instanceof DoubleBufferedSharedVideoResourceCanvasElement;

            if (this.isError || !isNextElementSameType) {
                this.vis.icanvas.clear_buffer_by_index(this.buffer_video_index);
            }


            this.vis.icanvas.unregister_video_buffer_index(this, this.buffer_video_index);

        } else {
            console.warn(`video not registered on clear from buffer ${this.visual_resource.toSourceURLName()}`);
        }

        this.visual_resource.resource_element = undefined;
        this.buffer_video_element = undefined;
        this.buffer_video_index = undefined;
    }

    restart() {
        // if (this.canvasElement) {
        //     //this.canvasElement.setHidden(this.isHidden);
        //     //this.vis.icanvas.addElement(this.canvasElement);
        // }
        // else {
        this.start();
        // }
    }
    collectCanvasElements(result) {
        // if (this.canvasElement) {
        //     result.push(this.canvasElement);
        // }
    }
    setHidden(value) {
        // this.canvasElement?.setHidden(value);
        // this.isHidden = value;
    }
    setDrawOrder(value) {

    }
    removeCanvasElement() {
        // if (this.canvasElement) {
        //     this.vis.icanvas.removeElement(this.canvasElement);
        // }
    }

    get isLoadingSuccess() {

        if (this.visual_resource == undefined) {
            return false;
        }
        if (this.visual_resource.isLoaded != true) {
            return false;
        }
        this.visual_resource.checkForErrors();
        if (this.visual_resource.isError == true) {
            return false;
        }
        return true;
    }
    toSourceURLName() {
        return this.visual_resource.toSourceURLName();
    }
    toSourceURLNameExt() {
        return this.visual_resource.resource_request.toURLNameAndExtension();
    }
    toSourceURL() {
        return this.visual_resource.toSourceURL();
    }
    get url() {
        return this.toSourceURL();
    }
    isLoading() {
        return this.visual_resource?.isLoading();
    }
    toRect(scaleToCanvas = true) {
        return this.visual_resource?.toRect(scaleToCanvas ? this.vis.icanvas : undefined);
    }

    onCanvasResized() {
        if (this.buffer_video_element) {
            this.updateElementSize();
        }
    }


    updateElementSize() {
        let image_rect = this.visual_resource.toRect(this.vis.icanvas);
        this.buffer_video_element.width = image_rect.w;
        this.buffer_video_element.height = image_rect.h;
    }

    // onElementError(elm, err) {
    //     this.onError?.(elm, err);
    // }

}
