import { Treeview } from "../view/treeview";
import { OpenModule } from "../externalModules/OpenModule";
import { ModuleIntegration } from "../externalModules/ModuleIntegration";
import { v4 as uuidv4 } from "uuid";
import * as htmlHelper from "../htmlHelper";
import $ from "jquery";
import "jquery-ui";
import "jquery-ui-css";
import { BaseModules, ModulesCommon } from "./ModulesCommon";


export var dialogOptions = {};
export var id;

export class ExternalModules extends BaseModules {
	static fullscreenIframeDivId = ModulesCommon.fullscreenIframeDivId;
	icanvas;
	authoringInterface;
	resources;
	account;
	server;
	static modules = [];
	json;
	audio;
	open_modules;
	state;

	constructor(state, resources, icanvas, account, server, audio, json = {}) {
		super();
		this.resources = resources;
		this.icanvas = icanvas;
		this.icanvas.externalModules = this;
		this.account = account;
		this.server = server;
		this.json = json;
		this.audio = audio;
		this.ready = false;
		this.state = state;
		this.auth = null;
	}

	get application() {
		return this.account.application;
	}
	stroageItemName() {
		return this.resources.combineJsonResourceName(this.application.name, this.account.name, "externalModules");
	}

	initialize() {
		//if (this.json == undefined) {
		// this.json = this.resources.getLocalStorageJsonItem(this.stroageItemName());

		if (!this.json.modules) {
			this.json.modules = ExternalModules.modules;
		}
		if (!this.open_modules) {
			this.open_modules = [];
		}
		var self = this;
		window.addEventListener("message", (event) => {
			if (event.data.userInfo) { this.auth = event.data.userInfo }
			if (this.auth?.user_id) { event.data.userInfo = this.auth; }
			self.postMessageHandler(event);
		}, false);
		//}
	}

	postMessageHandler(event) {
		this.open_modules.forEach((element) => {
			// if (element.module.html == event.origin && event.source === element.iframe.contentWindow) {
			element.windowMessage(event);
			// }
		});
	}

	saveState() {
		// this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
	}
	shutdown() {
		this.saveState();
	}

	getAuthorInterfaceName() {
		return "External Modules";
	}
	createAuthorInterfaceElement() {
		let layout = new Treeview();
		layout.buildElementFromJson(this.json, 1, true);
		return layout.element;
	}

	static newDialogiFrame(uuid, name = "module") {
		var fullscreen_iframe_div = document.getElementById(ExternalModules.fullscreenIframeDivId);

		var div_id = `module-instance-${uuid}`;
		// title="${name}"
		var div = htmlHelper.htmlToElement(`<div id=${div_id} ></div>`);

		fullscreen_iframe_div.parentElement.insertBefore(div, fullscreen_iframe_div.nextSibling);
		return div_id;
	}

	findModule(name) {
		for (let index = 0; index < this.json.modules.length; index++) {
			const element = this.json.modules[index];
			if (element.name == name) {
				return element;
			}
		}
	}
	findOpenModule(name) {
		for (let index = 0; index < this.open_modules.length; index++) {
			const element = this.open_modules[index];
			if (element.name == name) {
				return element;
			}
		}
	}
	openModule(name, isFullscreen = false, screenRect = undefined) {
		var module = this.findModule(name);
		if (this.open_modules[0]) { this.closeModule(this.open_modules[0]) }
		this.ready = false;

		isFullscreen = false;

		if (!module) { return undefined; }
		// if (this.icanvas.isMobile) { isFullscreen = true; }

		var uuid = uuidv4();


		if (isFullscreen) {
			id = ExternalModules.fullscreenIframeDivId;

			this.icanvas.deactivate();
			this.authoringInterface?.deactivate();
			this.audio.deactivate();

		} else {
			id = ExternalModules.newDialogiFrame(uuid, name);
		}

		var iframeDiv = document.getElementById(id);

		iframeDiv.style.display = 'none'
		iframeDiv.style.zIndex = 100;
		iframeDiv.innerHTML = null;

		var iframe = document.createElement("iframe");

		let userId;

		if (this.auth?.user_id) { userId = this.auth?.user_id.split("auth0|")[1] }

		var openModule = new OpenModule(module, id, iframe, uuid, userId, this.state);
		var self = this;

		if (isFullscreen) { iframeDiv.style.display = "block"; }
		else {
			// if (screenRect) {
				dialogOptions.width = this.icanvas.elements[1].width
				dialogOptions.height = this.icanvas.elements[1].height
				// dialogOptions.close = function () {
				// 	openModule.tryClose();
				// };
				dialogOptions.resize = function (event, ui) {
					self.icanvas.disableInput();
				};
				dialogOptions.resizeStop = function (event, ui) {
					self.icanvas.enableInput();
				};

				dialogOptions.resize = false;
				// dialogOptions.autoOpen = false
				// dialogOptions.dialogClass = "transparent-dialog";
				dialogOptions.position = {
					// my: `left+${Math.floor(screenRect.p.x)} top+${Math.floor(screenRect.p.y)}`,
					my: "left top",
					at: "left top",
					of: window,
					collision: "none",
				};

				dialogOptions.closeOnEscape = true;

				dialogOptions.open = function () {
					$(this).dialog("widget").find(".ui-dialog-titlebar").hide();
				}

				// dialogOptions.open = function (event, ui) {
				//   $(this).parent().children().children(".ui-dialog-titlebar-close").hide();
				// };
			// }

			$.ui.dialog.prototype._makeDraggable = function () {
				this.uiDialog.draggable({
					containment: true,
					handle: ".ui-dialog-titlebar",
				});
			};

			$(`#${id}`).dialog(dialogOptions).siblings('.ui-dialog-titlebar').hide();

			$(document).find(".ui-widget").css({ border: "0px solid black", padding: "0", zIndex: -1 })
			$(document).find(".ui-widget-content").css({ border: "0px solid black", padding: "0", zIndex: -1 })
			$(document).find(".ui-dialog").css({ border: "0px solid black",  padding: "0", overflow: "hidden", zIndex: -1 })
			$(document).find(".ui-dialog-content").css({ border: "0px solid black", padding: "0", overflow: "hidden", zIndex: -1 })

			dialogOptions.height = this.icanvas.elements[1].height;
			dialogOptions.width = this.icanvas.elements[1].width
			$(`#${id}`).dialog(dialogOptions);

			// $(`#${id}`).dialog('close')					
			// dialogOptions.height = dialogOptions.height + (dialogOptions.height * $(`#${id}`).siblings('.ui-dialog-titlebar').height() / this.icanvas.elements[1].height);

			// $(`#${id}`).dialog(dialogOptions);
		}

		iframeDiv.innerHTML = "";
		openModule.externalExtensions = this;

		if (isFullscreen) {
			iframe.style.height = "100vh";
			iframe.style.width = "100vw";
		} else {
			iframe.style.height = "100%";
			iframe.style.width = "100%";
		}

		iframe.onload = () => {
			iframe.contentWindow.focus()
			openModule.start();
		};

		openModule.iframe.src = module.html;
		iframeDiv.appendChild(iframe);

		// iframe.allowTransparency=true;
		// iframe.style.backgroundColor="#FFFFFF00";
		// iframe.contentWindow.document.open();
		// iframe.contentWindow.document.write(module.html);
		// iframe.contentWindow.document.close();

		this.open_modules[0] = openModule;

		//.push(openModule);

		return openModule;
	}

	closeModules() {
		var i = this.open_modules.length;
		while (i--) {
			this.closeModule(this.open_modules[i]);
		}
	}
	closeModuleByName(name) {
		var found = this.findOpenModule(name);
		if (found) {
			this.closeModule(found);
		}
	}
	closeModuleByDivId(name) {
		var found = this.findOpenModule(name);
		if (found) {
			this.closeModule(found);
		}
	}
	findOpenModuleByDivId(id) {
		for (let index = 0; index < this.open_modules.length; index++) {
			const element = this.open_modules[index];
			if (element.iframeDivId == id) {
				return element;
			}
		}
	}
	closeModule(module) {
		if (module.isClosed) {
			return;
		}
		module.isClosed = true;

		var iframeDiv = document.getElementById(module.iframeDivId);
		iframeDiv.style.display = "none";
		iframeDiv.innerHTML = "";

		if (module.iframeDivId == ExternalModules.fullscreenIframeDivId) {
			this.icanvas.reactivate();
			this.authoringInterface?.reactivate();
			this.audio.reactivate();
		} else {
			//var div = document.getElementById(module.iframeDivId);
			//div.remove();
			$(`#${module.iframeDivId}`).dialog("close");
			document.querySelector(`#${module.iframeDivId}`).parentElement.remove();

		}
		this.open_modules = this.open_modules.filter((each) => !(each === module));
	}
}