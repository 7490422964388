//import { VisualElements } from './sceneGraph/VisualElements';
//import { WebApplication } from './webApplication';

import { InputCapability } from '../input/InputCapability';
import { VideoFiles } from './VideoFiles';

export class InteractivePlatformCanvas {
    interactiveCanvas;
    name;
    brand;

    constructor() {
    }

    get canvas() {
        return this.interactiveCanvas.canvas;
    }

    get window_listeners() {
        return this.interactiveCanvas.window_listeners;
    }
    modifySelectionMode(selection_mode) {
        //selection_mode.input_capability = InputCapability.wasd;
    }

    isPlatform() {
        return false;
    }
    isTouchCapable() {
        return false;
    }
    apply_to_default_settings(){
      //  this.interactiveCanvas.state.application.setDefaultSetting(WebApplication.isShowConsoleLogOverlaySettingName,false);
      //  this.interactiveCanvas.state.application.setDefaultSetting(WebApplication.isShowMemoryDiagnosticsOverlaySettingName,false);
    }
    configure_visual_elements(){
    }
    select_video_content(set){
        var videos=new VideoFiles(set);
        return videos.getVideoPathByQuality();
    }
    initialize(interactiveCanvas) {

        this.interactiveCanvas = interactiveCanvas;
        this.name="unknown";
    }
    uninitialize() {
    }

    initialize_input() {}
	drawFrame() {

		// if (this.interactiveCanvas.isDrawDebugText) {
        //     var pos = { x: 20, y: 50 };
		// 	var len_px = this.interactiveCanvas.draw_text(this.name+"   - ", pos, 20);

        //     var pos = { x: pos.x+len_px+8, y: pos.y };
        //     this.interactiveCanvas.drawDeviceDiagnostics(pos)
        // }
    }
}