import { ConnectedStem } from "./ConnectedStem";

export class ConnectedStems {
  collection = [];  
  effects = [];
  //collection_unreferenced = [];
  audio;

  constructor(audio) {
    this.audio = audio;
  }

  startAudioOnGesture() {
    var now = Math.floor(Date.now() / 1000);
    this.collection.forEach((element) => {
      element.startAudioOnGesture(now);
    });
  }

  addReference(stem) {
    var found = this.findOrAddConnectionForStem(stem);
    found.addReference(stem);
  }
  removeReference(stem) {
    var found = this.findOrAddConnectionForStem(stem);
    found?.removeReference(stem);
  }

  findConnectionForStem(stem) {
    for (let index = 0; index < this.collection.length; index++) {
      const connectedStem = this.collection[index];
      if (connectedStem.audioSrc === stem.audioSrc) {
        return connectedStem;
      }
    }

    return undefined;
  }  
  
  findConnectionForEffectStem(stem) {
    for (let index = 0; index < this.effects.length; index++) {
      const connectedStem = this.effects[index];
      if (connectedStem.audioSrc === stem.audioSrc) {
        return connectedStem;
      }
    }

    return undefined;
  }

  findOrAddConnectionForStem(stem) {
    var found = this.findConnectionForStem(stem);
    if (found) {
      return found;
    }

    var toAdd = new ConnectedStem(this.audio, stem.audioSrc);
    this.collection.push(toAdd);
    return toAdd;
  }

  loadStem(stem) {
    var found = this.findOrAddConnectionForStem(stem);
    stem.load(found);
  }

  unloadStem(stem) {
    var found = this.findOrAddConnectionForStem(stem);
    stem.unload(found);
  }

  playStem(stem) {
    var found = this.findOrAddConnectionForStem(stem);
    stem.play(found);
  }

  updateStem(stem) {
    var found = this.findConnectionForStem(stem);
    if (found) {
      if (found.gain != stem.json.gain) {
        found.setGain(stem.json.gain,true);
      }
    }
  }

  setGainStem(stem, amount) {
    var found = this.findOrAddConnectionForStem(stem);

    stem.setGain(found, amount);
  }

  stopStem(stem) {
    var found = this.findOrAddConnectionForStem(stem);
    stem.stop(found);
  }

  addConnectionForEffectStem(stem) { 
    if(!stem.isEnabled){
      return;
    }

    var toAdd = new ConnectedStem(this.audio, stem.audioSrc);
    this.effects.push(toAdd);
    return toAdd;
  }

  playSoundEffect(stem){
    var effect = this.addConnectionForEffectStem(stem);
    effect.addReference(stem);
    effect.playFrom(stem);
    
    effect.onDone=()=>{
      const index = this.effects.indexOf(effect);
      if (index > -1) {
        this.effects.splice(index, 1);     
      }
    };
  }
}
