import * as htmlHelper from "../../htmlHelper.js";
import { UserInterfaceElement } from './UserInterfaceElement.js';


export class BreakUserInterfaceElement extends UserInterfaceElement {

    element;
    constructor(elements, json) {
        super(elements, json);
    }

    addToDOM() {

        this.element = htmlHelper.htmlToElement(`<br/>`);

        this.elements.div.appendChild(this.element);
    }
}
