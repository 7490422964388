import { AudioListenerScope } from "../audio_visual/audio/AudioListenerScope";

export class ScenePath {
  sceneGraphName;
  sceneName;
  constructor(sceneGraphName = undefined, sceneName = undefined) {
    this.sceneGraphName = sceneGraphName;
    this.sceneName = sceneName;
  }
  toAudioScope() {
    return new AudioListenerScope(this.sceneGraphName, this.sceneName);
  }

  static areLocationsTheSame(a,b){
    return a.sceneGraphName===b.sceneGraphName && a.sceneName===b.sceneName;
  }
}
