import { InteractiveEvent } from "./InteractiveEvent";


export class DragDropEvent extends InteractiveEvent {
  files;
  isDrag;

  constructor(icanvas = undefined, e = undefined, files = undefined) {
    super(icanvas, e);
    this.files = files;
  }
}
