
export class FadeoutTimer {
  fadeTimout;
  isFadedOut = false;
  seconds;
  subject;
  
  constructor(subject, seconds) {
    this.subject = subject;
    this.seconds = seconds;
  }

  onActivity() {
    if (this.isFadedOut) {
      this.fadeIn();
      this.start();
    } else if (this.fadeTimout) {
      this.start();
    }
  }

  start() {
    this.stop();
    if (this.seconds == undefined) {
      return;
    }

    this.fadeTimout = window.setTimeout(() => {
      this.fadeTimout = undefined;
      this.fadeOut();
    }, this.seconds * 1000);
  }

  stop() {
    if (this.fadeTimout) {
      window.clearTimeout(this.fadeTimout);
      this.fadeTimout = undefined;
    }
  }

  fadeOut() {
    this.isFadedOut = true;
    this.subject.hide();
  }

  fadeIn() {
    this.isFadedOut = false;
    this.subject.show();
  }
}
