//import { ImageResource } from './resources/ImageResource';
//import { ResourceCanvasElement } from './ResourceCanvasElement';
import { VisualElements } from './VisualElements';
import { Actions } from '../../sceneGraph/actions/actions';
import { WebApplication } from '../../webApplication';
import { TextTemplateReplace } from '../../textTemplateReplacement';
import { OnDrawingParams } from './OnDrawingParams';
let c2 = require("c2.js");

export class VisualElement {
    obj;
    resource_canvas_elements = []
    active_element;
    onReady;
    onDisplayed;
    isHidden;
    isStopped = true;
    onDrawing;
    constructor(obj) {
        this.obj = obj;
        this.addFromJson();
    }

    get_first_file_info() {

        if (this.active_element && this.active_element.url_file_info) {
            return this.active_element.url_file_info;
        }

        for (const each of this.resource_canvas_elements) {
            if (each.url_file_info) {
                return each.url_file_info;
            }
        }
    }

    on_display_timeout() {
        this.onResourceCanvasElementError(this.active_element);
    }

    resource_displaying(resource, success) {
        if (this.application.getSetting(WebApplication.isVideoPlayingTimeoutSettingName)) {
            if (this.visualElements.isAdded(this) == true) {
                this.visualElements.startDisplayTimeout(this);
            }
        }
    }

    resource_displayed(resource, success) {
        if (this.application.getSetting(WebApplication.isVideoPlayingTimeoutSettingName)) {
            if (this.visualElements.isAdded(this) == true) {
                this.visualElements.cancelDisplayTimeout(this);
            }
        }

        this.onDisplayed?.(resource, success);
    }

    // refreshImagePath() {

    //     if (this.active_element == undefined) {
    //         if (ImageResource.canCreateResourceFromJson(this.json, ImageResource.json_name, this.application)) {
    //             this.startLoadingVisualResourceFromJson(ImageResource, ImageResource.json_name);
    //         }
    //     }
    //     else {
    //         this.active_element.changeImagePath(this.json, ImageResource.json_name);
    //     }
    // }


    get resources() {
        return this.obj.resources;
    }
    get icanvas() {
        return this.obj.icanvas;
    }
    get json() {
        return this.obj.json;
    }
    get geometry() {
        return this.obj.geometry;
    }
    get scene() {
        return this.obj.scene;
    }
    get application() {
        return this.scene.application;
    }
    get visualElements() {
        return this.obj.visualElements;
    }
    get server_file_cache() {
        return this.visualElements.server_file_cache;
    }
    get visual_resource() {
        return this.active_element?.visual_resource;
    }
    setHidden(value) {
        this.isHidden = value;
        this.active_element?.setHidden(value);
    }
    setDrawOrder(value) {
        this.drawOrder = value;
        this.active_element?.setDrawOrder(value);
    }

    drawFrame(icanvas) {
        if (this.onDrawing) {
            var params = new OnDrawingParams();
            this.onDrawing(params);
            if (params.isCancel) {
                return;
            }
        }

        var background_color = this.json['background.color'];

        if (background_color) {
            var shape = this.geometry.get_absolute_rect_shape();
            icanvas.draw_rect_with_border(shape, [background_color[0], background_color[1], background_color[2],], background_color[3]);
        }

        var text = this.json['text'];

        if (text) {
            var shape = this.geometry.get_absolute_rect_shape();
            var pos = new c2.Point(shape.p.x + 4, shape.p.y + 24);

            if (text.includes('{{selectionMode.help}}')) {
                text = text.replace('{{selectionMode.help}}', this.scene.simulation.selectionMode.helpText())
            }


            icanvas.draw_text_with_newlines(text, pos, 20);
        }
    }

    getResourcePath() {
        return this.obj.getResourcePath();
    }

    startLoadingVisualResourceFromJson(type, json_property) {
        var resource = type.createResourceFromJson(this.json, json_property, this.getResourcePath(), this.json.resourcePath2, this.application);
        resource.onRunAction = (action) => {
            var actions = new Actions();
            actions.parseJson(action, this.application);
            actions.runActions(this.obj);
        }

        //var element = new ResourceCanvasElement(this, resource);
        var element = resource.createCanvasElement(this);


        //element.onError = (elm, err) => this.onElementError(elm, err);

        this.resource_canvas_elements.push(element);
    }

    addFromJson() {
        if (this.json == undefined) {
            return;
        }
        for (const each_property in this.json) {
            for (const each of VisualElements.visualResourceTypes(this.application)) {
                if (each.canCreateResourceFromJson(this.json, each_property, this.application)) {

                    this.startLoadingVisualResourceFromJson(each, each_property);
                }
            }
        }
    }

    onResourceCanvasElementError(resource_canvas_element) {

        console.warn("stop visual element on error: " + resource_canvas_element.toSourceURLNameExt());

        if (resource_canvas_element === this.active_element) {

            this.visualElements.remove(this);
            this.active_element.after_loading = undefined;
            this.active_element.onDrawing = undefined;
            this.active_element = undefined;

            var index = this.resource_canvas_elements.findIndex(each => each === resource_canvas_element);

            if (index != -1 && index + 1 < this.resource_canvas_elements.length) {

                var next_element = this.resource_canvas_elements[index + 1];
                resource_canvas_element.stop(next_element);

                if (!this.isStopped) {
                    console.info("start visual element: " + next_element.toSourceURLNameExt());
                    this.visualElements.add(this);
                    this.startLoadingElement(index + 1);
                }

            } else {
                resource_canvas_element.stop(undefined);
            }
        }

        // this.removeAndReplaceResourceCanvasElement(elm);
    }

    // onElementError(elm, err) {
    //     this.removeAndReplaceResourceCanvasElement(elm);
    // }

    // removeAndReplaceResourceCanvasElement(element) {
    //     console.warn("replacing element:" + this.url);
    // }
    firstNonErrorFileInfo() {
        for (let index = 0; index < this.resource_canvas_elements.length; index++) {
            const each_element = this.resource_canvas_elements[index];

            if (each_element.isError) {
                continue;
            }

            var url = each_element.visual_resource.resource_request.toUrlPath();

            var blob_file_info = this.server_file_cache.server_asset_lookup[url];

            if (blob_file_info) {
                return blob_file_info;
            }
        }
        return undefined;
    }
    start() {
        this.visualElements.add(this);

        if (this.active_element == undefined) {
            for (let index = 0; index < this.resource_canvas_elements.length; index++) {
                const each_element = this.resource_canvas_elements[index];

                if (each_element.isError) {
                    continue;
                }

                this.isStopped = false;
                this.startLoadingElement(index);//0
                break;
            }
        }
        else {
            this.isStopped = false;
            this.active_element.restart();
            this.onReady?.(this.active_element, true);
        }
    }

    stop(next_resource_canvas_element) {
        this.visualElements.remove(this);

        this.isStopped = true;
        // for (const each of this.resource_canvas_elements) {
        //     each.stop();
        // }
        this.active_element?.stop(next_resource_canvas_element);

    }

    firstResourceCanvasElement() {
        return this.resource_canvas_elements[0];
    }

    startLoadingElement(index) {
        if (index < this.resource_canvas_elements.length) {
            var to_load = this.resource_canvas_elements[index];
            to_load.after_loading = () => this.after_loading_callback(index);
            to_load.start_loading();
        }
        else {
            this.active_element = undefined;
            this.onReady?.(undefined, false);
        }
    }

    active_element_on_drawing(params) {
        this.onDrawing?.(params);
    }

    after_loading_callback(index) {
        var loaded = this.resource_canvas_elements[index];
        if (loaded.isLoadingSuccess) {
            this.active_element = loaded;
            this.active_element.onDrawing= this.active_element_on_drawing.bind(this);
            this.active_element.setHidden(this.isHidden);
            if (this.visualElements.isAdded(this) == true) {
                this.active_element.start();
                this.onReady?.(this.active_element, true);
            }
        }
        else {
            // if (each == this.active_element) {
            //     this.active_element = undefined;
            // }

            if (this.visualElements.isAdded(this) == true) {

                var next_index = index + 1;
                if (next_index < this.resource_canvas_elements.length) {
                    var next_resource = this.resource_canvas_elements[next_index];
                    console.info("start visual element: " + next_resource.toSourceURLNameExt());
                    this.startLoadingElement(next_index);
                }
                else {
                    this.onReady?.(undefined, false);
                }
            }
        }
    }


    collectCanvasElements(result) {
        this.active_element?.collectCanvasElements(result);
        // for (const each of this.resource_canvas_elements) {
        //     each.collectCanvasElements(result);
        // }
    }

    isLoading() {
        if (this.active_element) {
            return this.active_element.isLoading();
        }
        for (const each of this.visualElements.visualResourceTypes(this.application)) {
            if (each.isLoading()) {
                return true;
            }
        }
        return false;
    }
    isReady() {
        return this.active_element != undefined;
    }
    toRect(scaleToCanvas = true) {
        return this.active_element?.toRect(scaleToCanvas);
    }
}