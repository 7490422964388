import { LocalServerFileCache } from "./LocalServerFileCache";
import { StackLayout } from "./view/stackLayout";

export const DataLocation = {
  None: 1,
  Unknown: 2,
  Client: 3,
  Cloud: 4,
};

export class WebApplicationServer {
  resources;
  name;
  json;
  browser;
  account;
  app;
  server_file_cache;

  get application(){
    return this.app;
  }
  get url(){
    return window.location.href;
  }
  get pathname(){
    return window.location.pathname;
  }
  get search(){
    return window.location.search;
  }
  get hash(){
    return window.location.hash;
  }
  constructor(resources, app, name = "") {
    this.resources = resources;    
    resources.server = this;
    this.name = name;
    this.app = app;
    this.server_file_cache = new LocalServerFileCache(this, app);
  }

  stroageItemName(append_extension = true) {
    return this.resources.combineJsonResourceNames([this.name, "application.server.storage"], append_extension);
  }

  setClientCustomerAccount(account) {
    this.account = account;
	this.name = account.firstName
  }
  async initialize() {
    this.json = this.resources.getLocalStorageJsonItem(this.stroageItemName());
    await this.server_file_cache.initialize();
  }
  saveState() {
    this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
    this.server_file_cache.saveState();
  }
  shutdown() {
    this.saveState();
    this.server_file_cache.shutdown();
  }
  setBrowser(v) {
    this.browser = v;
  }

  collectEditableProperties(layout) {
    this.server_file_cache.collectEditableProperties(layout);
  }
  getAuthorInterfaceName() {
    return "server";
  }
  createAuthorInterfaceElement() {
    let layout = new StackLayout();
    this.collectEditableProperties(layout);
    return layout.element;
  }

  addAuthorInterfaceElementToTreeview(treeview) {
    let elm = this.createAuthorInterfaceElement();
    treeview.addItem(this.getAuthorInterfaceName(), elm, false);
  }
}
