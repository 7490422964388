import { AudioAmbianceGroup } from "./AudioAmbianceGroup";

export class AmbianceAudioListener {
  audio;
  ambianceGroup;

  constructor(audio) {
    this.audio = audio;
    this.ambianceGroup = new AudioAmbianceGroup(this.audio);
  }

  onStartAudioOnGesture() {
    this.ambianceGroup.onStartAudioOnGesture();
  }

  addAudioAmbiance(ambiance) {
    this.ambianceGroup.add(ambiance);
 
  }

  removeAudioAmbiance(ambiance, next_ambiance = undefined) {
    if (next_ambiance !== undefined) {
      this.ambianceGroup.transition(ambiance, next_ambiance);
    } else {
      this.ambianceGroup.remove(ambiance);
    }
  }

  // start() {
  //   this.ambianceGroup.start();
  // }
}
