// /* context

// https://rotato.app/blog/transparent-videos-for-the-web
// https://www.tutorialspoint.com/How-do-I-make-a-transparent-canvas-in-HTML5
// https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Manipulating_video_using_canvas

// */

import { WebApplication } from '../../webApplication.js';
//import { CanvasElement } from "./CanvasElement.js";
import { VideoDisplayToElement } from './VideoDisplayToElement.js';

export class VideoFileToCanvasElement extends VideoDisplayToElement {

  constructor(video_file_to_element) {
    super(video_file_to_element);
  }

  onVideoFramesPerSecondSettingChangedSubscription;

  onVideoFramesPerSecondSettingChanged(value) {
    var fps = this.GetFPSSetting();
    this.video_file_to_element.setAutoInvalidateFrequencyPerSecond(fps);
  }

  addedToInteractiveCanvas() {

    //console.log("video:canvas")
    this.onVideoFramesPerSecondSettingChangedSubscription = (v) => { this.onVideoFramesPerSecondSettingChanged(v) };
    this.icanvas.State.application.addSettingSubscription(WebApplication.VideoFramesPerSecondSettingName, this.onVideoFramesPerSecondSettingChangedSubscription)

    var fps = this.GetFPSSetting();
    this.video_file_to_element.setAutoInvalidateFrequencyPerSecond(fps);
  }

  removedFromInteractiveCanvas() {
    this.video_file_to_element.setAutoInvalidateFrequencyPerSecond(0);
    this.icanvas.State.application.removeSettingSubscription(WebApplication.VideoFramesPerSecondSettingName, this.onVideoFramesPerSecondSettingChangedSubscription)
    this.onVideoFramesPerSecondSettingChangedSubscription = undefined;
  }

  GetFPSSetting() {
    var fps = this.icanvas.State.application.getSetting(WebApplication.VideoFramesPerSecondSettingName);
    fps = Math.min(120, fps);
    fps = Math.max(0, fps);
    return fps;
  }

  draw() {
    this.icanvas.draw_log.push({ message: "video", details: this.element.currentSrc?.split('/').pop() });

    let image_rect = this.resource.toRect(this.icanvas);
    this.icanvas.ctx.drawImage(this.element, 0, 0, image_rect.w, image_rect.h);
  }
}