
export class ConsoleMessageElement {
  html_element;
  console_message_data;
  uncaught_exception_data;
  uncaught_rejection_data;
  duplicate_count = 0;
  item_set_count;
  item_count;
  incrementDuplicateCount() {
    this.duplicate_count += 1;

    if (this.item_count != undefined) {
      this.item_count += 1;
    }
    this.updateElement();
  }

  updateElement() {
    this.html_element.textContent = this.generateTextContent();
    this.updateElementColor();
  }

  updateElementColor() {
    if (this.console_message_data) {
      switch (this.console_message_data.level) {
        case 'error':
          this.html_element.style.color = 'red';
          break;
        case 'warn':
          this.html_element.style.color = 'orange';
          break;
        case 'info':
          this.html_element.style.color = 'white';
          break;
        default: // log, debug, etc.
          this.html_element.style.color = 'white'; // Or your default color
      }
    } else if (this.uncaught_exception_data || this.uncaught_rejection_data) {
      this.html_element.style.color = 'red'; // Red for errors
    }
  }
  generateTextContent() {

    var result = "";

    if (this.item_set_count != undefined) {
      result += this.item_set_count + " - " + this.item_count + " ";
    }

    if (this.console_message_data) {

      if (this.console_message_data.level !== 'log ') {
        //result += "";
      } else {
        result += this.console_message_data.level + " ";
      }

      result += this.console_message_data.message;

    } else if (this.uncaught_exception_data) {
      result += this.uncaught_exception_data.message.message;

    } else if (this.uncaught_rejection_data) {
      result += this.uncaught_rejection_data.reason;

    } else {
      //result += "";
    }

    if (this.duplicate_count > 0) {
      result += "+" + this.duplicate_count;
    }

    return result;
  }
  isSameAsConsoleLog(message) {
    if (!this.console_message_data) {
      return false;
    }
    return this.console_message_data.message === message.message;
  }

}
