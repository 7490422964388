export class VideoFile {
        static regex = /^([\w\s.-]+)\.brtv=(\d+)\.frmv=(\d+)\.resv=(\w+)\.size=(\d+)\.vidc=(\w+)$/;
        static regex2 = /^([\w\s.-]+)\.brtv=(\d+)\.crfv=(\d+)\.frmv=(\d+)\.resv=(\w+)\.size=(\d+)\.vidc=(\w+)$/;
    
    static isVideoFile(path) {
        return VideoFile.regex.test(path) || VideoFile.regex2.test(path);
    }

    constructor(filename) {
        var split = filename.split(".");
        split.shift();

       
        //const regex = /^([\w\s.-]+)\.brtv=(\d+)\.frmv=(\d+)\.resv=(\w+)\.size=(\d+)\.vidc=(\w+)$/;
        var match = filename.match(VideoFile.regex);

        if (match) {
            this.filename = match[1];
            this.bitrate = parseInt(match[2], 10);
            this.framerate = parseInt(match[3], 10);
            this.resolution = match[4];
            this.size = parseInt(match[5], 10);
            this.videoCodec = match[6];
            return;
        } 

        match = filename.match(VideoFile.regex2);

        if (match) {
            this.filename = match[1];
            this.bitrate = parseInt(match[2], 10);
            this.crf = parseInt(match[3], 10)
            this.framerate = parseInt(match[4], 10);
            this.resolution = match[5];
            this.size = parseInt(match[6], 10);
            this.videoCodec = match[7];
        } 
        
        
        // else {
        //     throw new Error(`Invalid filename format: ${filename}`);
        // }
    }
    getAspectRatio() {
        const [width, height] = this.resolution.toLowerCase().split("p");
        return width / height;
    }

    isHighDefinition() {
        const resolutions = ["720p", "1080p", "1440p", "2160p", "4320p"]; // Example HD resolutions
        return resolutions.includes(this.resolution.toLowerCase());
    }

    // Placeholder (implementation would be application-specific)
    getDownloadUrl() {
        // Logic to determine download URL based on filename or other data
        return `https://example.com/videos/${this.filename}`;
    }
    getFullFilename() {
        const crfPart = this.crf !== undefined ? `.crfv=${this.crf}` : '';
        return `${this.filename}.brtv=${this.bitrate}${crfPart}.frmv=${this.framerate}.resv=${this.resolution}.size=${this.size}.vidc=${this.videoCodec}`;
      }
}
