export class AudioAmbianceSet {
  collection = [];
  audio;
  name;
  constructor(name, audio) {
    this.name = name;
    this.audio = audio;
  }

  addAmbiance(ambiance) {
    this.collection.push(ambiance);
    ambiance.ambianceSet = this;
  }

  // start() {}

  // stop() {}

}
