import { SceneGraphAudioVisualStateComponent } from './SceneGraphAudioVisualStateComponent';


export class SceneGraphAudioVisualStateBaseComponent extends SceneGraphAudioVisualStateComponent {

    constructor(json) {
        super(json);
    }

    initializeFromJson() {
        super.initializeFromJson();
    }

    get short() {
        return this.json.short;
    }
    get med() {
        return this.json.med;
    }
    get long() {
        return this.json.long;
    }
}
