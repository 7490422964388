export class Action {
  static typename = "action";
  verb;
  name;

  constructor(verb, name) {
    this.verb = verb;
    this.name = name;
  }
   canRun(param) {
    return true;
   }
   run(param) {}
}

export class ActionWithValue extends Action {
  value;
  value_context;

  constructor(verb, name, json) {
    super(verb, name);
    this.value = json["value"];
    this.value_context = json["value_context"];
  }
}

