export class OpenModule {
	module;
	iframeDivId;
	iframe;
	externalExtensions;
	integrations = [];
	data_context;
	uuid;
	isClosed;
	userId;
	state;
	get name() {
		return this.module.name;
	}
	constructor(module, iframeDivId, iframe, uuid, userId, state) {
		this.module = module;
		this.iframeDivId = iframeDivId;
		this.iframe = iframe;
		this.uuid = uuid;
		this.userId = userId;
		module.addIntegrations(this);
		this.state=state;
	}

	start() {
		this.integrations.forEach(async (element) => {
			await element.start(this.userId);
		});
	}
	tryClose() {
		this.externalExtensions.closeModule(this);
	}

	exit(message = undefined) {
		this.tryClose();
	}
	windowMessage(message = undefined) {
		this.integrations.forEach((element) => {
			element.windowMessage(message);
		});
	}
	ready(message = undefined) { return

	} onStarted(message = undefined) { }

	addActivationRewards(rewards) {		
		this.integrations.forEach((element) => {
		  if (element.addActivationRewards) {
			element.addActivationRewards(rewards);
		  }
		});
	}
}
