import { SceneGraphAudioVisualScript } from './SceneGraphAudioVisualScript';

export class SceneGraphAudioVisualScripts {
    json;

    constructor(json) {
        this.json = json;
    }

    initializeFromJson() {
    }

    getDefaultScript() {
        for (let each in this.json) {
            var script = this.json[each];
            if (script.isDefault) {
                var obj = new SceneGraphAudioVisualScript(script);
                obj.initializeFromJson();
                return obj;
            }
        }
    }
}
