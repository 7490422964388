//https://github.com/ren-yuan/c2.js
let c2 = require("c2.js");

export function mouseEventToPoint(e) {
  return new c2.Point(e.x, e.y);
}

export class Geometry {
  obj;
  shape;

  get isRelativeTo() {
    return this.obj.geometry_isRelativeTo;
  }

  border_size = 2;
  draw(icanvas, drawScope) { }
  to_point(isRelativeTo) { }

  get_absolute_rect_shape() { }


  get scale() {
    var v = this.obj.json["geometry.scale"];
    return v != undefined ? v : 1;
  }
  setScale(scaler){
    this.obj.json["geometry.scale"]=scaler;
  }

  updateShape() {
  }
}


