/* context

https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Using_Web_Audio_API
https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Migrating_from_webkitAudioContext
https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Web_audio_spatialization_basics
https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Best_practices

https://developer.chrome.com/blog/autoplay/#webaudio

*/

import { NumberEditableProperty } from "../../view/propertyEditor";
import { StackLayout } from "../../view/stackLayout";
import { ConnectedStems } from "./ConnectedStems";

export class WebAudio {
  audioContext;

  account;
  isWaitingForGesture;
  connectedStems;
  static ambiancelayerName = "ambiance";
  static musiclayerName = "music";
  static effectslayerName = "effects";
  static layerNames = [WebAudio.ambiancelayerName, WebAudio.musiclayerName, WebAudio.effectslayerName];
  layers = {};
  volumeNode;

  constructor(resources, account) {
    this.resources = resources;
    this.account = account;

    this.connectedStems = new ConnectedStems(this);
  }

  connectNodeToLayer(layer, node) {
    let layerNode = this.layers[layer];
    if (layerNode) {
      node.connect(this.audioContext.destination);
    }
  }

  initialize() {
    this.tryStartAudio();
  }

  tryStartAudio() {
    if (this.audioContext != undefined) {
      return true;
    }
    try {
      this.audioContext = new AudioContext();
      this.isWaitingForGesture = this.audioContext.state === "suspended";

      this.volumeNode = this.audioContext.createGain();
      this.volumeNode.connect(this.audioContext.destination);

      WebAudio.layerNames.forEach((element) => {
        this.layers[element] = this.audioContext.createGain();
        this.layers[element].connect(this.volumeNode);
      });
      return true;
    } catch (e) {}
    return false;
  }

  uninitialize() {}

  start() {}

  archiveVolume;
  deactivate() {
    this.isDeactivate = true;
    this.audioContext.suspend();
    //this.archiveVolume=this.volumeNode.gain.value;
    // this.volumeNode.gain.value=0;
    //this.volumeNode.disconnect();
  }

  reactivate() {
    if (this.isDeactivate) {
      this.isDeactivate = false;

      this.audioContext.resume();
      // this.volumeNode.gain.value=this.archiveVolume;
      //this.volumeNode.connect(this.audioContext.destination);
    }
  }

  stroageItemName() {
    return this.resources.combineJsonResourceName(this.account.application.name, this.account.name, "audio.storage");
  }

  saveState() {
    this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
  }

  shutdown() {
    this.saveState();
  }

  playSoundEffects(stems){
    stems.forEach(element => {
      this.connectedStems.playSoundEffect(element);
    });
  }

  startAudioOnGesture() {
    this.tryStartAudio();

    if (this.isWaitingForGesture) {
      this.audioContext.resume();
      this.isWaitingForGesture = false;
      if (this.onStartAudioOnGesture) {
        this.onStartAudioOnGesture();
      }

      console.log("AudioContext resume");
      this.connectedStems.startAudioOnGesture();

    }
  }

  onStartAudioOnGesture;

  drawFrame(icanvas) {}
  mousedown(icanvas, e) {
    this.startAudioOnGesture();
  }
  mouseup(icanvas, e) {}
  mousemove(icanvas, e) {}
  keydown(icanvas, ievent) {
    this.startAudioOnGesture();
  }
  onTouchTap(e) {
    this.startAudioOnGesture();
  }
  onTouchPan(e) {}
  onTouchSwipe(e) {
    this.startAudioOnGesture();
  }
  onTouchDistance(e) {}
  onTouchRotate(e) {}
  onTouchGesture(e) {}

  static createEditablePropertyForGainNode(node, name) {
    let prop = new NumberEditableProperty();
    prop.name = name;
    prop.setValue = (v) => {
      node.gain.value = v;
    };
    prop.getValue = () => {
      return node.gain.value;
    };
    prop.minValue = 0;
    prop.maxValue = 3;
    prop.defaultValue = node.gain.defaultValue;
    return prop;
  }

  collectEditableProperties(layout) {
    let prop = WebAudio.createEditablePropertyForGainNode(this.volumeNode, "Volume");
    layout.addAsTableRow(prop.getEditorElements());

    WebAudio.layerNames.forEach((element) => {
      let prop = WebAudio.createEditablePropertyForGainNode(this.layers[element], element + " Volume");
      layout.addAsTableRow(prop.getEditorElements());
    });
  }

  getAuthorInterfaceName() {
    return "audio";
  }
  createAuthorInterfaceElement() {
    let layout = new StackLayout();
    this.collectEditableProperties(layout);
    return layout.element;
  }
}
