import { ResourceCanvasElement } from '../audio_visual/visual/ResourceCanvasElement';

export class FileResource {

    url;
    url_file_info;
    server_file_cache;
    
    constructor() {

    }
    checkForErrors() {
        return false;
    }
    createCanvasElement(vis) {
        return new ResourceCanvasElement(vis, this);
    }

    static getPathWithoutExtension(path) {
        const lastDotIndex = path.lastIndexOf('.');
        if (lastDotIndex === -1) {
            return path; // No extension found
        }
        return path.substring(0, lastDotIndex);
    }

    static getFullpathFilenameWithoutExtension(filePath) {
        const filename = filePath.split(/[\/\\]/).pop(); // Get filename (with extension)
        const lastDotIndex = filename.lastIndexOf('.');
        if (lastDotIndex === -1) {
            return filename; // No extension found
        }
        return filename.substring(0, lastDotIndex);
    }
}
