export class AudioListenerScope {
  regionName;
  areaName;
  region;
  area;

  gain = 1;
  isPlayingRegion = false;
  isPlayingArea = false;

  static fromScenePath(path, area = undefined, region = undefined) {
    var r = new AudioListenerScope(path.sceneGraphName, path.sceneName);
    r.area = area;
    r.region = region;
    return r;
  }
  
  constructor(regionName, areaName, area = undefined, region = undefined) {
    this.regionName = regionName;
    this.areaName = areaName;
    this.area = area;
    this.region = region;
  }
}
