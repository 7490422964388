import { SceneGraphAudioVisualStateBaseComponent } from './SceneGraphAudioVisualStateBaseComponent';
import { SceneGraphAudioVisualStateEventComponent } from './SceneGraphAudioVisualStateEventComponent';

export class SceneGraphAudioVisualState {
    json;
    base_component;
    event_components;

    constructor(json) {
        this.json = json;
    }

    initializeFromJson() {
        if(this.json.components){
            this.base_component = new SceneGraphAudioVisualStateBaseComponent(this.json.components.base);
            this.base_component.initializeFromJson();

            if(this.json.components.events){
                event_components={};
                for (let each in this.json.components.events) {
                    event_components[each]=new SceneGraphAudioVisualStateEventComponent(this.json.components.events[each]);
                    event_components[each].initializeFromJson();
                }
            }
        }
    }

    get isDefault() {
        return this.json.isDefault;
    }
    get video_suffix() {
        return this.json["video.suffix"];
    }
    get image_suffix() {
        return this.json["image.suffix"];
    }
    get ambiance_suffix() {
        return this.json["ambiance.suffix"];
    }
    get ambiance_gain() {
        return this.json["ambiance.gain"];
    }
    get audioAmbiance() {
        return this.json["audioAmbiance"];
    }
    get transition_from() {
        return this.json["transition.from"];
    }
}
