import { VideoDisplayToElement } from './VideoDisplayToElement.js';

export class VideoFileToVideoElement extends VideoDisplayToElement {

  is_added_to_viewport;
 
  constructor(video_file_to_element) {
    super(video_file_to_element);
  }

  addedToInteractiveCanvas() { 
    //console.log("video:video")
    if (!this.is_added_to_viewport) {
      this.updateElementSize(this.icanvas);
      this.icanvas.viewport.appendChild(this.element);
      this.is_added_to_viewport = true;
    }
  }

  removedFromInteractiveCanvas() {
    if (this.is_added_to_viewport) {
    
      this.icanvas.viewport.removeChild(this.element);
      this.is_added_to_viewport = false;
    }
  }

  draw() {

  }
  validate() {
   
  }

  onCanvasResized() {
    if (this.is_added_to_viewport) {
      this.updateElementSize(this.icanvas);
    }
  }


  updateElementSize() {
    let image_rect = this.resource.toRect(this.icanvas);
    this.element.width = image_rect.w;
    this.element.height = image_rect.h;
  }
}