import * as htmlHelper from "./../htmlHelper.js";

export class Treeview {
  root;
  element;
  element_json;
  static indentAmount = 12;

  constructor(create_empty_element = false) {
    if (create_empty_element) {
      this.buildEmptyElement();
    }
  }
  static addStyleToListElement(element) {
    element.style.listStyleType = "none";
    element.style.margin = 0;
    element.style.padding = 0;
  }

  addListItemParent(name, list_element, depth, isExpanded = true, content = undefined) {
    let li = document.createElement("li");

    let label = htmlHelper.htmlToElement(`<span class="caret"></span>`);
    Treeview.addIndentStyle(label, depth, false);

    label.addEventListener("click", function () {
      this.parentElement.querySelector(".nested").classList.toggle("active");
      this.classList.toggle("caret-down");
    });

    li.appendChild(label);

    let labelHeader = htmlHelper.htmlToElement(`<span >${name}</span>`);
    li.appendChild(labelHeader);

    let ul = document.createElement("ul");

    Treeview.addStyleToListElement(ul);
    ul.classList.add("nested");
    if (isExpanded) {
      ul.classList.add("active");
    }

    if (content) {
      Treeview.addIndentStyle(content, depth, true);
      ul.appendChild(content);
    }

    li.appendChild(ul);
    list_element.appendChild(li);
    return ul;
  }

  static addIndentStyle(element, depth, isChildItem) {
    let amount = depth * Treeview.indentAmount;
    if (isChildItem) {
      amount += 20;
    }
    element.style.marginLeft = `${amount}px`;
  }

  addItem(name, content = undefined, isExpanded = true) {
    var item;
    if (this.root) {
      item=this.addListItemParent(name, this.root, 1, isExpanded, content);
    } else {
      item=this.addListItemParent(name, this.element, 0, isExpanded, content);

    }
    item.parentElement.style.marginTop="24px";
  }

  populateTreeWithJsonData(json, list_element, depth, expandedToDepth = undefined) {
    for (const [key, value] of Object.entries(json)) {
      if (value instanceof Object) {
        let obj_item = this.addListItemParent(
          key,
          list_element,
          depth,
          expandedToDepth != undefined ? expandedToDepth != 0 : true
        );
        this.populateTreeWithJsonData(
          value,
          obj_item,
          depth + 1,
          expandedToDepth != undefined ? expandedToDepth - 1 : undefined
        );
      } else {
        let item = htmlHelper.htmlToElement(`<li>${key} : ${value}</li>`);
        Treeview.addIndentStyle(item, depth, true);
        list_element.appendChild(item);
      }
    }
  }
  buildEmptyElement() {
    this.element = document.createElement("ul");
    Treeview.addStyleToListElement(this.element);

    //let root_name =  "data";
    //this.root = this.addListItemParent(root_name, this.element, 0);
    //this.populateTreeWithJsonData(json, root, 1, expandedToDepth);
  }
  buildElementFromJson(json, expandedToDepth = undefined) {
    this.element_json = json;
    this.element = document.createElement("ul");
    Treeview.addStyleToListElement(this.element);

    let root_name = json["name"] || "data";
    this.root = this.addListItemParent(root_name, this.element, 0);
    this.populateTreeWithJsonData(json, this.root, 1, expandedToDepth);
  }
}
