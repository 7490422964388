
export class AutoCanvasElementInvalidation {
	timer;
	elements;
	fps;
	icanvas;
	isStop;

	constructor(fps, icanvas) {
		this.fps = fps;
		this.elements = [];
		this.icanvas = icanvas;
	}

	start() {
		this.timerCallback();
	}
	removeElement(element){
		let index = this.elements.indexOf(element);
		if (index >= 0) {
			this.elements.splice(index, 1);
		}
	}
	get isEmpty(){
		return this.elements.length==0;
	}

	timerCallback() {
		if (this.isStop) {
			return;
		}		

		this.invalidateElements();
		this.icanvas.try_invalidated_draw();

		this.timer = setTimeout(() => {
			this.timerCallback();
		}, this.milliseconds)
	}

	stop() {
		this.isStop = true;
		if (this.timer) {
			clearTimeout(this.timer);
			this.timer = undefined;
		}
	}

	get milliseconds() {
		return (1 / this.fps) * 1000;
	}

	invalidateElements() {
		for (const each of this.elements) {
			each.invalidate();
		}
	}
}
