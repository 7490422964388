import { Action } from "./action";
import { ActivateAction } from "../sceneActions/ActivateAction";
import { BackAction } from "../sceneActions/BackAction";
import { NavigateAction } from "../sceneActions/NavigateAction";
import { NavigateHomeAction } from "../sceneActions/NavigateHomeAction";
import { OpenAreaSizedModuleAction } from "../sceneActions/OpenAreaSizedModuleAction";
import { OpenFullscreenModuleAction } from "../sceneActions/OpenFullscreenModuleAction";
import { OpenSceneSizedModuleAction } from '../sceneActions/OpenSceneSizedModuleAction';
import { ToggleAction } from "../sceneActions/ToggleAction";
import { PlayAudioAction } from '../sceneActions/playAudio';
import { ExitModule } from "../sceneActions/ExitModule";
import { CallWebFunction } from "../sceneActions/CallWebFunction";
import { ToggleApplicationSettingAction } from '../sceneActions/ToggleApplicationSettingAction';
import { ChangeSelectionMode } from '../sceneActions/ChangeSelectionMode';
import { ToggleInteractiveVisibility } from '../sceneActions/ToggleInteractiveVisibility';

export class Actions {
  static typename = "actions";

  actions = [];

  addActionFromJson(j, application = undefined) {
    var a = newActionFromJson(j, application);
    this.actions.push(a);
  }

  parseJson(json, application = undefined) {
    for (let each in json) {
      if (each == Action.typename) {
        this.addActionFromJson(json[each], application);
      } else if (each == Actions.typename) {
        for (let each_action in json[each]) {
          this.addActionFromJson(json[each][each_action], application);
        }
      }
    }
  }

  runActions(param = undefined) {
    for (let each in this.actions) {
      this.actions[each].run(param);
    }
  }
  static newJsonFromVerb(verb, name = undefined) {
    return { verb: verb, name: name };
  }
}

export function newActionFromJson(json, application = undefined) {
  var verb = json.verb;
  var name = json.name;
  var obj;

  if (application == undefined) {
    console.log("action with no application");
  }

  if (verb == "toggle") {
    obj = new ToggleAction(verb, name, json);
  } else if (verb == "toggle_application_setting") {
    obj = new ToggleApplicationSettingAction(verb, name, json, application);
  } else if (verb == "navigate") {
    obj = new NavigateAction(verb, name, json);
  } else if (verb == "back") {
    obj = new BackAction(verb, name, json);
  } else if (verb == "home") {
    obj = new NavigateHomeAction(verb, name, json);
  }else if (verb == "change-selection-mode") {
    obj = new ChangeSelectionMode(verb, name, json);
  }else if (verb == "toggle-interactive-visibility") {
    obj = new ToggleInteractiveVisibility(verb, name, json);
  }   else if (verb == "activate") {
    obj = new ActivateAction(verb, name, json);
  } else if (verb == "openFullscreenModule") {
    obj = new OpenFullscreenModuleAction(verb, name, json);
  } else if (verb == "openAreaSizedModule") {
    obj = new OpenAreaSizedModuleAction(verb, name, json);
  } else if (verb == "openSceneSizedModule") {
    obj = new OpenSceneSizedModuleAction(verb, name, json);
  } else if (verb == "play audio") {
    obj = new PlayAudioAction(verb, name, json);
  } else if (verb == "ExitModule") {
    obj = new ExitModule(verb, name, json);
  } else if (CallWebFunction.verb_names.some(each => each == verb)) {
    obj = new CallWebFunction(verb, name, json);
  } else {
    console.warn("unknown action " + verb);
    obj = new Action(verb, name);
  }
  return obj;
}
