import { CanvasElement } from "./CanvasElement.js";


export class HTML5CanvasElement extends CanvasElement {
  element;


  constructor(resource, element, is_invalidating_draw = true) {
    super(resource, is_invalidating_draw);
    this.element = element;
  }

  toSourceURL() {
    return this.element.currentSrc;
  }
}
