
import { SizeType } from "../audio_visual/visual/resources/ImageResource.js";
import { GeometryElements } from '../geometry/GeometryElements.js';
import { VisualElement } from "../audio_visual/visual/visualElement.js";


export class InteractivePointOfInterest {
  static typename = "point_of_interest";
  interactive;
  geometry;
  json;

  visual_element;
  isVisualError = false;

  get resources() {
    return this.interactive.resources;
  }
  get icanvas() {
    return this.interactive.icanvas;
  }
  get isImageRelativeToScene() {
    return this.json["image.relativeTo"] == "scene";
  }
  get scene() {
    return this.interactive.scene;
  }
  get area() {
    return this.interactive.area;
  }
  get visualElements() {
    return this.scene.visualElements;
  }
  get application() {
    return this.scene.application;
  }

  constructor(interactive, json) {
    this.interactive = interactive;
    this.json = json;

    // this.visual_element = new VisualElement(this);
    // this.setHidden(true);
  }

  setHidden(value) {
    this.visual_element.setHidden(value);
  }

  // set area(value) {
  //   this.geometry.isRelativeTo = value;
  // }

  // setAreaAndScene(area, scene) {

  // }

  get geometry_isRelativeTo() {
    if (this.isImageRelativeToScene) {
      return this.scene;
    } else {
      return this.area;
    }
  }

  start() {

    if (this.visual_element == undefined) {
      this.visual_element = new VisualElement(this);
      this.setHidden(true);

      // this.visual_element.onReady = () => this.start_visuals_loaded();
    }

    this.geometry = GeometryElements.createGeometryFromObjectWithJson(this);

    this.visual_element.onReady = (resource, success) => this.onVisualReady(resource, success);

    this.visual_element.onError = (resource) => this.onVisualError(resource);
    this.visual_element.start();

    this.visual_element.onDrawing = (params) => this.onVisualDrawing(params);
  }

  stop() {
    this.visual_element.stop();
  }

  onVisualDrawing(params) {

    var ux_scene = this.scene.simulation.getUXScene();
    var ux_scene_interactive_layer = ux_scene.sceneInteractiveLayer;

    if (ux_scene_interactive_layer.isFadedOut) {
      params.isCancel = true;
    }
  }
  onVisualError(resource) {
    this.isVisualError = true;
  }

  onVisualReady(resource, success) {
    // collect elements
    //this.canvasElement.draw_order = this.interactive.scene.sceneLayerOrder;

    if (this.isVisualError) {
      this.icanvas.invalidate();
      this.isVisualError = false;
    }
    if (success) {
      this.visual_element.setDrawOrder(1.2);
    }

    this.icanvas.try_invalidated_draw();
  }
  getResourcePath() {
    return this.json.resourcePath || this.interactive.getResourcePath();
  }

  collectCanvasElements(result) {
    this.visual_element.collectCanvasElements(result);
  }
}
