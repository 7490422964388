import { ExternalModule } from './../../../../cabin-library/client/src/externalModules/ExternalModule';
import { GameCenterModuleIntegration } from './../../../../cabin-library/client/src/externalModules/GameCenterModuleIntegration';

export class CardCraftExternalModule extends ExternalModule{
    constructor(){
        super();
        this.name="card craft";
        this.html="https://nonprod-card-craft.nmp.nonprod-sinclairstoryline.com";
        //this.html="http://localhost:13004";
        this.addIntegrations=(openModule)=> openModule.integrations[0] = new GameCenterModuleIntegration(openModule);		
    }
}