import { ImageCanvasElement } from "../imageCanvasElement";
import { ResourceCanvasElement } from '../ResourceCanvasElement';
import { WebApplication } from '../../../webApplication';
import { FileResourceRequest } from '../../../resources/FileResourceRequest';
import { SizeType } from './SizeType';
import { FileResource } from '../../../resources/FileResource';

let c2 = require("c2.js");

export class ImageResource extends FileResource {
  static errrorImage;
  static webp_extension = ".webp";
  static png_extension = ".png";
  static svg_extension = ".svg";
  static webp_category = "visuals/images/webp/";
  static png_category = "visuals/images/png/";
  static svg_category = "visuals/images/svg/";
  static json_name = "image";
  // static requestImageResource(request) {
  //   return this.requestPngImageResource(request);
  // }
  // static disposeImageResource(resource) { }

  // static requestWebPImageResource(request) { }
  // static disposeWebPImageResource(resource) { }

  // static requestPngImageResource(request) {
  //   request.setExtension(".png");
  //   request.setCategoryPath("visuals/images/png/");
  //   return new ImageResource(request);
  // }

  //static disposePngImageResource(resource) { }

  checkForErrors() {
    return false;
  }
  createCanvasElement(vis) {
    return new ResourceCanvasElement(vis, this);
  }
  notifyError() {
    if (!this.isError) {
      this.isError = true;
    }
  }
  static canCreateResourceFromJson(json, property) {
    return property == "image" && json[property] !== undefined;
  }

  static requestResource(name, path, path2, webapp) {
    let request = new FileResourceRequest(path, name, "", "", path2, true, false, false, webapp);

    var resource;

    var webp_enabled = webapp.getSetting(WebApplication.IsWebpEnabledSettingName);
    var webp_image_path = request.fullpathWithExtension(ImageResource.webp_extension, ImageResource.webp_category);
    var svg_image_path = request.fullpathWithExtension(ImageResource.svg_extension, ImageResource.svg_category);
    var webp_image_asset = webapp.server.server_file_cache.findFile(webp_image_path);
    var svg_image_asset = webapp.server.server_file_cache.findFile(svg_image_path);

    if(svg_image_asset){
      request.setExtension(ImageResource.svg_extension);
      request.setCategoryPath(ImageResource.svg_category);
      resource = new SvgImageResource();
    }
    else if (webp_enabled && webp_image_asset) {
      request.setExtension(ImageResource.webp_extension);
      request.setCategoryPath(ImageResource.webp_category);

      if (webapp.getSetting(WebApplication.IsWebpWebAssemblyEnabledSettingName)) {
        resource = new WebPWebAssemblyImageResource();
      } else {
        resource = new WebPImageResource();
      }
    }
    else {
      request.setExtension(ImageResource.png_extension);
      request.setCategoryPath(ImageResource.png_category);
      resource = new PngImageResource();
    }

    if (resource) {

      resource.resource_request = request;
      resource.url = resource.resource_request.toUrlPath();
      resource.server_file_cache = webapp.server.server_file_cache;
      resource.url_file_info = resource.server_file_cache.server_asset_lookup[resource.url];
      resource.type = "image";
    }

    return resource;
  }

  static createResourceFromJson(json, property, path, path2, webapp) {
    if (this.canCreateResourceFromJson(json, property)) {
      let result = this.requestResource(json.image, path, path2, webapp);
      result.centerRotateDegrees = json["image.rotate"];
      result.scale = json["image.scale"];
      result.corner_radius = json["image.corner_radius"];
      return result;
    }
  }
  resource_element;
  resource_request;
  loading_promise;
  isError;
  isLoaded;

  getRectangle;
  sizeType = SizeType.ScaleToFitScreen;

  centerRotateDegrees;
  scale;
  corner_radius;
  onVisualLoaded;

  constructor() {
    super();
  }

  toSourceURLName() {
    return this.resource_request.name;
  }
  start_loading(server_file_cache, resource_canvas_element) {

    if (this.resource_request.application.getSetting(WebApplication.IsVerboseLoggingSettingName)) {
      console.log(`image:loading ${this.resource_request.toUrlPath()}`);
    }
    var self = this;

    this.resource_element = new Image();
    this.resource_request.application.memory_diagnostics.start_download("image", this.resource_request, this.resource_element);

    this.loading_promise = new Promise((resolve, reject) => {
      this.resource_element.addEventListener("load", () => {
        self.loading_promise = undefined;
        self.isLoaded = true;
        self.isError = false;
        self.onVisualLoaded?.(self);
        resolve(this.resource_element);
      });
      self.resource_element.addEventListener("error", function () {
        self.loading_promise = undefined;
        self.isLoaded = true;
        self.isError = true;
        self.onVisualLoaded?.(self);
        console.warn("missing resource: " + self.resource_request.filename);
        resolve(self.resource_element);
      });

      this.server_file_cache.loadFromCacheOrUrl(this.resource_element, this.url);
      // this.resource_element.load();
    });
  }

  initialize() {

  }
  start(resource_canvas_element) {

  }
  stop() {

  }
  isLoading() {
    return this.loading_promise != undefined;
  }
  //if(ImageResource.errrorImage==undefined){
  // ImageResource.errrorImage=new Image(1920, 1080);
  // }
  getLoadingPromise() {
    return this.loading_promise;
  }
  pixel_size() {
    return [this.imageWidth(), this.imageHeight()];
  }
  imageWidth() {
    var result = 0;

    if (this.isError) {
      result = 1920;
    }
    else if (this.resource_element && this.resource_element.width != undefined) {
      result = this.resource_element.width;
    } else if (this.url_file_info && this.url_file_info.width != undefined) {
      result = this.url_file_info.width;
    }

    var scaler = this.scale == undefined ? 1 : this.scale;
    result *= scaler;

    return result;
  }

  imageHeight() {
    var result = 0;

    if (this.isError) {
      result = 1080;
    }
    else if (this.resource_element && this.resource_element.height != undefined) {
      result = this.resource_element.height;
    } else if (this.url_file_info && this.url_file_info.height != undefined) {
      result = this.url_file_info.height;
    }

    var scaler = this.scale == undefined ? 1 : this.scale;
    result *= scaler;

    return result;
  }

  toRect(icanvas = undefined) {
    // if (!image || image.width == 0 || image.height == 0) {
    //   console.warn(`scene ${this.name} undefined image`);
    //   return new c2.Rect(0, 0, 0, 0);
    // }
    if (icanvas && this.sizeType == SizeType.ScaleToFitScreen) {
      var hRatio = icanvas.get_width() / this.imageWidth();
      var vRatio = icanvas.get_height() / this.imageHeight();
      var ratio = Math.min(hRatio, vRatio);
      var result = new c2.Rect(0, 0, this.imageWidth() * ratio, this.imageHeight() * ratio);
      return result;
    }
    if (this.sizeType == SizeType.ScaleToFitRectangle) {
      let rectangle = this.getRectangle();
      var hRatio = rectangle.w / this.imageWidth();
      var vRatio = rectangle.h / this.imageHeight();
      var ratio = Math.min(hRatio, vRatio);
      var result = new c2.Rect(
        rectangle.p.x,
        rectangle.p.y,
        this.imageWidth() * ratio,
        this.imageHeight() * ratio
      );
      return result;
    }
    if (!icanvas || this.sizeType == SizeType.Normal) {

      let rectangle = this.getRectangle?.();

      var result = new c2.Rect(rectangle ? rectangle.p.x : 0, rectangle ? rectangle.p.y : 0, this.imageWidth(), this.imageHeight());
      return result;
    }
  }

  getCanvasElement() {
    //if (this.canvas_element == undefined) {
    //   this.canvas_element = new ImageCanvasElement(this, this.resource_element);
    // }
    // return this.canvas_element;
    return new ImageCanvasElement(this, this.resource_element);
  }
}

export class ImageElementImageResource extends ImageResource {

  constructor() {
    super();
  }
}
export class PngImageResource extends ImageElementImageResource {

  constructor() {
    super();
  }
}
export class WebPImageResource extends ImageElementImageResource {

  constructor() {
    super();
  }
}

export class WebPWebAssemblyImageResource extends ImageResource {

  constructor() {
    super();
  }
}
export class SvgImageResource extends ImageResource {

  constructor() {
    super();
  }
}
