import * as htmlHelper from "../../htmlHelper.js";
import { UserInterfaceElement } from './UserInterfaceElement.js';


export class LableUserInterfaceElement extends UserInterfaceElement {

    element;
    constructor(elements, json) {
        super(elements, json);
    }

    addToDOM() {

        this.element = htmlHelper.htmlToElement(`<span >${this.json.content}</span>`);

        if (this.json.margin) {
            this.element.style.margin = this.json.margin;
        }
        // this.element.style.color="black";
        this.elements.div.appendChild(this.element);
    }
    value_string(){
        return this.element.value;
    }
}
