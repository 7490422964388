import { OpenAreaSizedModuleAction } from './OpenAreaSizedModuleAction';
import { Action } from "../actions/action";

export class OpenSceneSizedModuleAction extends Action {
  value;
  value_context;
  constructor(verb, name, json) {
    super(verb, name);
    this.value = json["value"];
    this.value_context = json["value_context"];
  }

  run(interactive) {
    var area_rect = interactive.scene.getScreenSpaceAreaRect();

    var shrink=.1;
    area_rect.p.x+=area_rect.w*shrink;
    area_rect.p.y+=area_rect.h*shrink;
    area_rect.w-=area_rect.w*shrink*2;
    area_rect.h-=area_rect.h*shrink*2;

    OpenAreaSizedModuleAction.openModule(interactive,this,area_rect);
  }
}

