
export class SceneGraphAudioVisualStateComponent {
    json;

    constructor(json) {
        this.json = json;
    }

    initializeFromJson() {
    }
}
