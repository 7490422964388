import { ScenePath } from "./sceneGraph/ScenePath";
import { EmptySceneGraphNode, SceneChangeRequest, SceneGraphNode } from "./sceneGraph/scene_graph_node";

export class Player {
  account;
  json;
  simulation;
  active_location;

  constructor(account, simulation) {
    this.account = account;
    this.json = account.json_player;
    this.simulation = simulation;

    if (this.json.tokenCount === undefined) {
      this.json.tokenCount = 0;
    }
    if (this.json.homeLocation === undefined) {
     //var sg = this.sceneGraphSet.defaultNavigationSceneGraph;
      this.json.homeLocation = new ScenePath("rustic-log-cabin","cabin-splash-screen");
    }
    if (this.json.location === undefined) {
      this.json.location = this.json.homeLocation;
    }
    if (this.json.navigationHistory === undefined) {
      this.json.navigationHistory = [this.json.location];
    }
  }

  get sceneGraphSet() {
    return this.simulation.sceneGraphSet;
  }

  // get simulation() {
  //   return this.sceneGraphSet.simulation;
  // }

  get sceneGraphNode() {
    return this.simulation.sceneGraphNode;
  }

  get navigationHistory() {
    return this.json.navigationHistory;
  }

  set navigationHistory(v) {
    this.json.navigationHistory = v;
  }

  on_scene_change_request_success(request) {
    this.json.location = request.location;
    this.active_location = request.location;

   // if (this.navigationHistory.length == 0 || this.navigationHistory[this.navigationHistory.length - 1] !== path) {
      if (request.isPushNavigationHistory) {
        this.navigationHistory.push(request.location);

        if (this.navigationHistory.length > 100) {
          this.navigationHistory = this.navigationHistory.splice(0, 100 / 2);
        }
      }
   // }
  }

  startChangeLocation(path, isPushNavigation = true) {
    var request = new SceneChangeRequest(path, isPushNavigation);
   // request.replace_location = this.active_location;
    this.simulation.startSceneChange(request);
    // if (this.json.location === path) {
    //   return;
    // }

    // this.sceneGraphSet.tryPopulateDefaultPaths(path);
    // var success = this.sceneGraphNode.TryReplaceScene(this.sceneGraphSet, this.json.location, path);

    // if (success) {

    //   this.simulation.selectionMode.startState();
    //   this.json.location = path;

    //   if (this.navigationHistory.length == 0 || this.navigationHistory[this.navigationHistory.length - 1] !== path) {
    //     if (isPushNavigation) {
    //       this.navigationHistory.push(path);

    //       if (this.navigationHistory.length > 100) {
    //         this.navigationHistory = this.navigationHistory.splice(0, 100 / 2);
    //       }
    //     }
    //   }

    //   this.sceneGraphNode.invalidate();
    // }
  }

  // initializeSceneGraphNode() {
  //   if (!this.sceneGraphNode.tryAddAndStartScenePath(this.sceneGraphSet, this.json.location, true)) {
  //     console.warn(`scene path not found in scene graph set ${JSON.stringify(this.json.location)}`);
  //     if (this.sceneGraphNode.tryAddAndStartScenePath(this.sceneGraphSet, this.json.homeLocation, true)) {
  //       this.json.location = this.json.homeLocation;
  //       this.navigationHistory.push(this.json.location);
  //     }
  //   }

  //   this.simulation.selectionMode.startState();
  // }

  canNavigateBack() {
    return this.navigationHistory.length > 1;
  }

  navigateBack() {
    if (this.canNavigateBack()) {
      this.navigationHistory.pop();
      let path = this.navigationHistory[this.navigationHistory.length - 1];
      this.startChangeLocation(path, false);
    }
  }

  navigateHome() {
    this.startChangeLocation(this.json.homeLocation);
  }
}
