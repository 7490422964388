import { NavigationMode, SceneInteractionMode } from '../../input/selectionMode';
import { Action } from '../actions/action';


export class ChangeSelectionMode extends Action {
  value;
  constructor(verb, name, json) {
    super(verb, name);
    this.value = json["value"];
  }

  run(interactive) {
    console.log("action:ChangeSelectionMode");
    var scene=interactive.scene;
    var sim = scene.simulation;

    if(this.value == "interactive"){
      sim.selectionMode.transitionState(new SceneInteractionMode())

    }else  if(this.value == "navigation"){

      sim.selectionMode.transitionState(new NavigationMode())
    }

  }
}


