import * as htmlHelper from "./../htmlHelper.js";

export class StackLayout {
  element;
  table_layout_cache;

  get table_layout() {
    if (!this.table_layout_cache) {
      this.table_layout_cache = htmlHelper.BorderlessTable();
      this.add(this.table_layout_cache);
    }
    return this.table_layout_cache;
  }

  constructor() {
    let ul = document.createElement("ul");
    StackLayout.addStyleToListElement(ul);
    this.element = ul;
  }
  addAsTableRow(elements) {
    let table = this.table_layout;
    htmlHelper.addTableRow(table, elements);
  }

  add(element) {
    let li = document.createElement("li");
    li.appendChild(element);
    this.element.appendChild(li);
  }

  static addStyleToListElement(element) {
    element.style.listStyleType = "none";
    element.style.margin = 0;
    element.style.padding = 0;
  }
}
