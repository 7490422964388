
import { FileResourceRequest } from "./resources/FileResourceRequest.js";
import { AudioResource } from "./audio_visual/audio/resources/audioResource.js";

export class Resources {
  server;
  fetched_json = {};
  static JsonExtension = ".json";

  async Initialize() {

    var scene_graphs_promise = this.getOrFetchJsonPromise(this.getNavigationGraphPathFromName(""));

    scene_graphs_promise = scene_graphs_promise.then(async (result) => {

      let fetchSceneGraphs;

      result.navigationGraphs?.forEach((element) => {
        var path = this.getNavigationGraphPathFromName(element);

        if (fetchSceneGraphs === undefined) {
          fetchSceneGraphs = [];
        }

        fetchSceneGraphs.push(this.getOrFetchJsonPromise(path));
      });

      if (fetchSceneGraphs) {
        await Promise.all(fetchSceneGraphs);
      }
    });

    var file_manifest_promise = this.getOrFetchJsonPromise(this.getFileManifestJsonPath());

    await Promise.all([scene_graphs_promise, file_manifest_promise]);
  }

  getDataFilePathByNameAndExtension(name, ext) {
    return `./assets/data/${name}${ext}`;
  }

  async getOrFetchSceneGraphJson(name) {
    var path = this.getNavigationGraphPathFromName(name);

    return await this.getOrFetchJson(path);
  }

  getOrFetchJsonPromise(path) {

    var found = this.fetched_json[path];

    if (!found) {
      var p = this.getJsonPromise(path);
      p = p.then((result) => {

        if (result == 404) {
          console.error(`resources:404 error ${path}`);
        }
        this.fetched_json[path] = result;
        return result;
      });
      return p;
    }

    return Promise.resolve(found);
  }
  isJsonFetched(path) {
    return this.fetched_json[path] !== undefined;
  }
  getFetchedJson(path) {
    return this.fetched_json[path];
  }

  async getOrFetchJson(path) {

    var found = this.fetched_json[path];

    if (!found) {
      found = await this.getJsonAsync(path);

      if (found == 404) {
        console.error(`resources:404 error ${path}`);
      }
      this.fetched_json[path] = found;
    }
    return found;
  }

  getNavigationGraphPathFromName(name) {
    var filename = name;
    if (filename) {
      filename += ".";
    }
    filename += "navigation.graph";
    var path = this.getJsonPath(filename);
    return path;
  }

  getFileManifestJsonPath() {
    return this.getJsonPath("file_manifest");
  }

  getJsonPath(name) {
    return `./assets/data/${name}.json`;
  }

  getJsonPromise(path) {
    console.log(`resources:fetch ${path}`);
    var p = fetch(path);

    p = p.then(async (result) => {
      return await result.json();
    });

    return p;
  }

  async getJsonAsync(path) {
    console.log(`resources:fetch ${path}`);
    const r = await fetch(path);
    const j = await r.json();

    return j;
  }

  combineJsonResourceNames(args, append_extension = true) {
    let result = "";
    for (let each in args) {
      let each_value = args[each];
      if (each_value) {
        result += each_value;
        result += ".";
      }
    }
    if (append_extension) {
      result += "json";
    } else {
      result = result.slice(0, -1);
    }

    return result;
  }

  combineJsonResourceName() {
    let result = "";
    for (let each in arguments) {
      let each_value = arguments[each];
      if (each_value) {
        result += each_value;
        result += ".";
      }
    }

    result += "json";

    return result;
  }

  requestMp3AudioResource(request) {
    request.setExtension(".mp3");
    request.setCategoryPath("audio/");
    request.application = this.server.app;
    //let found = this.audios[request.fullpath];

    //if (found) {
    //  return found;
    // }

    request.server_file_cache = this.server.server_file_cache;
    var found = new AudioResource(request);
    //this.audios[request.fullpath] = found;
    return found;
  }

  disposeMp3AudioResource(resource) {
    resource?.dispose();
  }

  disposeResource(resource) {
    if (resource.type == "audio") {
      this.disposeMp3AudioResource(resource);
    }
  }
  requestResource(name, type, path, path2, onVisualLoaded) {
    let request = new FileResourceRequest(path, name, "", "", path2, true, false, false, onVisualLoaded);
    var r;

    if (type == "audio") {
      r = this.requestMp3AudioResource(request);
    }

    r.type = type;
    return r;
  }

  getLocalStorageItem(name) {
    let found = window.localStorage.getItem(name);
    // if (!found) {
    //   found = ifNotFound;
    // }
    return found;
  }

  getLocalStorageJsonItem(name, createIfNotFound = true) {
    let item = this.getLocalStorageItem(name);

    if (item) {
      
      if (item === "\"{}\"") {
        item = "{}";
      }

      let json = JSON.parse(item);
      return json;
    }
    else {
      if (createIfNotFound) {
        this.setLocalStorageItemAsJson(name, {}, true);
        return this.getLocalStorageJsonItem(name, false);
      }
    }

    return item;
  }

  deleteLocalStorageItem(name) {
    var item = window.localStorage.getItem(name);

    if (!item) {
      return;
    }

    window.localStorage.removeItem(name);
  }

  setLocalStorageItem(name, value, createIfNotFound = true) {

    if (!createIfNotFound && window.localStorage.getItem(name) === null) {
      return;
    }
    window.localStorage.setItem(name, value);
  }
  setLocalStorageItemAsJson(name, object_value, createIfNotFound = true) {
    let value = JSON.stringify(object_value);
    this.setLocalStorageItem(name, value, createIfNotFound);
  }
}
