import { SceneObjects } from "./scene_objects.js";
import { SceneObject } from './SceneObject.js';
import { Interactives } from "./interactives.js";
import { AudioAmbiance } from "../audio_visual/audio/audioAmbiance.js";
import { Interactive } from "./Interactive.js";
import { VisualElement } from "../audio_visual/visual/visualElement.js";
import { ScenePath } from "./ScenePath.js";
import { InteractiveLayer, InteractiveLayerJson } from "./InteractiveLayer.js";
import { InteractiveLayers } from "./InteractiveLayers.js";
import { AudioListenerScope } from "../audio_visual/audio/AudioListenerScope.js";
import { AmbianceContext } from "../audio_visual/audio/AmbianceContext.js";
import { Actions } from './actions/actions.js';
import { GeometryElements } from '../geometry/GeometryElements.js';
let c2 = require("c2.js");
let geometry_js = require("../geometry.js");

export class Scene {
  scene_graph;
  scene_graph_node;
  name;
  json;
  visual_element;
  audioAmbiance;
  objects;
  interactiveLayers;

  constructor(scene_graph, name, json) {
    this.scene_graph = scene_graph;
    this.name = name;
    this.json = json;


    this.initializeFromJson();

    this.audioAmbiance = new AudioAmbiance(
      this.toAudioContext(),
      this.json.audioAmbiance,
      this.getResourcePath.bind(this)
    );

  }

  get sceneInteractiveLayer() {
    return this.interactiveLayers.default_layer;
  }
  get sceneInteractiveLayerInteractives() {
    return this.sceneInteractiveLayer.interactives;
  }
  get visualElements() {
    return this.scene_graph_node.visualElements;
  }
  get resources() {
    return this.scene_graph.resources;
  }
  get icanvas() {
    if (!this.scene_graph_node) {
      console.log("error: unloaded scene canvas access")
    }
    return this.scene_graph_node.icanvas;
  }
  get simulation() {
    return this.scene_graph_node.simulation;
  }
  get sceneGraph() {
    return this.scene_graph;
  }
  get sceneGraphNode() {
    return this.scene_graph_node;
  }
  get sceneLayerOrder() {
    return this.json.sceneLayerOrder;
  }
  get scene() {
    return this;
  }
  get localScene() {
    return this;
  }
  get globalScene() {
    return this;
  }
  get application() {
    return this.scene_graph.application;
  }
  get server() {
    return this.scene_graph.simulation.server;
  }
  get server_file_cache() {
    return this.server.server_file_cache;
  }
  get visualElements() {
    return this.scene_graph_node?.visualElements;
  }
  get draw_order() {
    return this.sceneLayerOrder;
  }
  get geometry_isRelativeTo(){
    return undefined;
  }
  // get visual_resource() {
  //   return this.visual_element.visual_resource;
  // }
  // get canvasElement() {
  //   return this.visual_element.canvasElement;
  // }

  applyAudioVisualScriptItem(av_script_item) {

  }

  initializeFromJson() {

    if (this.sceneGraph.active_audio_visual_script) {
      if (this.sceneGraph.active_audio_visual_script.includesScene(this.name)) {
        var script_item = this.sceneGraph.active_audio_visual_script.getActiveItem();
        this.applyAudioVisualScriptItem(script_item);
      }
    }

    if (this.json.sceneLayerOrder == undefined) {
      this.json.sceneLayerOrder = 1;
    }

    this.interactiveLayers = new InteractiveLayers(this);
    //this.interactiveLayers.pushDefaultLayer();

    //this.objects = new SceneObjects(this, this.json.objects);
    this.objects?.initializeFromJson();

    this.interactiveLayers.setDefaultLayer(this.json);
    this.interactiveLayers.initializeFromJson();

    if (this.json.inactivityFadeoutSeconds != undefined) {
      this.interactiveLayers.default_layer.json.inactivityFadeoutSeconds = this.json.inactivityFadeoutSeconds;
    }
    // for (let each in this.json.interactive) {
    //   var i = new Interactive(this.json.interactive[each], this);
    //   //i.scene = this;
    //   this.interactiveLayers.addInteractive(i);
    // }
  }

  activate_event(ievent) {
    this.interactiveLayers.activate_event(ievent);
  }

  activate(value, value_context) {
    this.interactiveLayers.activate(value, value_context);
  }

  getResourcePathFunction() {
    return this.getResourcePath.bind(this);
  }

  toAudioContext() {
    var audioContext = new AmbianceContext(AudioListenerScope.fromScenePath(this.toScenePath(), this, this.sceneGraph));
    return audioContext;
  }

  toScenePath() {
    return new ScenePath(this.scene_graph.name, this.name);
  }

  push(item) {
    // if (item instanceof Interactive) {
    //   this.interactiveLayers.addInteractive(item);
    //   item.scene = this;
    // }else
    //  else if (item instanceof SceneObject) {
    //    this.objects.sceneObjects.push(item);
    //  } 

    if (item instanceof InteractiveLayerJson) {
      this.interactiveLayers.pushLayer(item.json);
    }
  }

  removeTemporary() {
    this.sceneInteractiveLayer.removeTemporary();
  }
  canNavigateBack() {
    return this.simulation.player.canNavigateBack();
  }
  navigateBack() {
    this.simulation.player.navigateBack();
  }
  navigateHome() {
    this.simulation.player.navigateHome();
  }
  navigate(scene_name, scene_graph_name = undefined) {
    if (scene_graph_name == undefined) {
      scene_graph_name = this.sceneGraph.name;
    }
    this.simulation.player.startChangeLocation(new ScenePath(scene_graph_name, scene_name));
  }

  graphStarted(previousPath = undefined) { }

  start(previousScene = undefined) {

    console.info("start scene: " + this.name);
    

    if (this.visual_element == undefined) {
      //
      this.visual_element = new VisualElement(this);
      // this.visual_element.onReady = () => this.start_visuals_loaded();
    }

    this.geometry = GeometryElements.createGeometryFromObjectWithJson(this);

    this.visual_element.onReady = (resource, success) => this.onVisualReady(resource, success);
    this.visual_element.onDisplayed = (resource, success) => this.onVisualDisplayed(resource, success);
    this.visual_element.onError = (resource) => this.onVisualError(resource);
    this.visual_element.start();


    this.objects?.start();
    this.interactiveLayers.start();

    this.scene_graph_node.listener.addAudioAmbiance(this.audioAmbiance);

    if (this.json["scene.event.start"]) {

      var actions = new Actions();
      actions.parseJson(this.json["scene.event.start"], this.application);
      actions.runActions(this);
    }
  }

  isVisualError = false;

  onVisualError(resource) {
    this.isVisualError = true;
  }

  onVisualDisplayed(resource, success) {

    if (success && !this.isVisualError) {
      console.info("scene visual is displayed");

    }
  }
  onVisualReady(resource, success) {
    // collect elements
    //this.canvasElement.draw_order = this.interactive.scene.sceneLayerOrder;


    if (this.isVisualError) {
      this.icanvas.invalidate();
      this.isVisualError = false;
    }

    this.icanvas.try_invalidated_draw();
  }
  // start_visuals_loaded() {
  //   if (this.scene_graph_node) {
  //     this.icanvas.try_invalidated_draw();
  //   }
  // }
  stop(nextScene = undefined) {

    console.info("stop scene: " + this.name);
    this.objects?.stop();
    this.interactiveLayers.stop();
    var next_vis = nextScene?.visual_element;
    this.visual_element.stop(next_vis?.firstResourceCanvasElement());
    this.scene_graph_node.listener.removeAudioAmbiance(this.audioAmbiance, nextScene?.audioAmbiance);
    this.removeTemporary();
  }

  graphStopped(nextPath = undefined) { }

  getResourcePath() {
    return this.json.resourcePath || this.scene_graph.json.resourcePath;
  }


  deriveRectForScene(scaleToCanvas = true) {
    // for (let each in this.scenes) {
    //   let i = this.scenes[each];
    //   if (i === scene) {
    //     continue;
    //   }

    //   if (i.visual_resource && !i.visual_resource.isLoading()) {
    //     return i.toRect();
    //   }
    // }

    var file_info = this.visual_element?.firstNonErrorFileInfo();

    if (file_info) {
      var defaultW = file_info.width;
      var defaultH = file_info.height;

    } else {
      var defaultW = 3840;
      var defaultH = 2160;
    }

    if (scaleToCanvas) {
      var hRatio = this.simulation.icanvas.get_width() / defaultW;
      var vRatio = this.simulation.icanvas.get_height() / defaultH;
      var ratio = Math.min(hRatio, vRatio);
      return new c2.Rect(0, 0, defaultW * ratio, defaultH * ratio);
    }

    return new c2.Rect(0, 0, defaultW, defaultH);

    //return result;
    //  return this.simulation.icanvas.toRect();
  }

  toRect(scaleToCanvas = true) {
    if (scaleToCanvas && this.scene_graph_node == undefined) {
      return new c2.Rect(0, 0, 0, 0)
    }

    if (this.visual_element.isReady()) {
      return this.visual_element.toRect(scaleToCanvas);
    } else {
      return this.deriveRectForScene(scaleToCanvas);
    }
  }

  getScreenSpaceAreaRect() {
    return this.toRect();
  }

  drawFrame(icanvas) {
    // if (this.visual_resource?.isLoading()) {
    //   return;
    // }
    if (this.scene_graph_node == undefined) {
      return;
    }

    this.interactiveLayers.drawFrame(icanvas);
  }
  mousedown(ievent) {
    this.interactiveLayers.mousedown(ievent);
  }
  mouseup(ievent) {
    this.interactiveLayers.mouseup(ievent);
  }
  mousemove(ievent) {
    if (!this.isIEventOnGeometry(ievent)) {
      return;
    }

    this.interactiveLayers.mousemove(ievent);
  }
  keydown(ievent) {
    this.interactiveLayers.keydown(ievent);
  }
  keyup(ievent) {
    this.interactiveLayers.keyup(ievent);
  }
  onTouchTap(ievent) {
    this.interactiveLayers.onTouchTap(ievent);
  }
  onTouchPan(ievent) {
    this.interactiveLayers.onTouchPan(ievent);
  }
  onTouchSwipe(ievent) {
    this.interactiveLayers.onTouchSwipe(ievent);
  }
  onTouchDistance(ievent) {
    this.interactiveLayers.onTouchDistance(ievent);
  }
  onTouchRotate(ievent) {
    this.interactiveLayers.onTouchRotate(ievent);
  }
  onTouchGesture(ievent) {
    this.interactiveLayers.onTouchGesture(ievent);
  }

  file_dropped(ievent) {
    this.interactiveLayers.file_dropped(ievent);
  }
  drag_file(ievent) {
    this.interactiveLayers.drag_file(ievent);
  }
  static getNameFromFilename(file) {
    return file.name;
  }
  static newJsonFromFile(file) {
    return {};
  }

  // addInteractive(name, json, isTemporary = false) {
  //   return this.interactiveLayers.default_layer.addInteractive(name, json, isTemporary);
  // }
  // removeInteractive(name) {
  //   this.interactiveLayers.default_layer.removeInteractive(name);
  // }

  findInteractiveByName(name, isTemporary) {
    return this.interactiveLayers.default_layer.findInteractiveByName(name, isTemporary);
  }

  convertEventWithPointToRelativePoint(e) {
    var asMouse = { x: e.offsetX, y: e.offsetY };

    var rect = this.toRect();

    var result = new c2.Point(asMouse.x / rect.w, asMouse.y / rect.h);

    return result;
  }
  isIEventOnGeometry(ievent) {
    var geometry = this.getScreenSpaceAreaRect();
    let mouse_point = geometry_js.mouseEventToPoint(ievent.e);
    return geometry.intersects(mouse_point);
  }
}
