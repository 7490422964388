import { Interactives } from "./interactives";

export class InteractiveLayerJson {
  json;

  constructor(json) {
    this.json = json;
  }

  clone() {
    return new InteractiveLayerJson(this.json);
  }
}

export class InteractiveLayer {
  parent;
  json;
  interactives;
  fadeTimout;
  isFadedOut;
  isStarted = false;

  constructor(parent, json) {
    this.parent = parent;
    this.json = json;
    this.interactives = new Interactives(this, json.interactive);
  }

  get scene() {
    return this.parent.scene;
  }
  initializeFromJson() {
    this.interactives.initializeFromJson();
  }

  getResourcePath() {
    return this.parent.getResourcePath();
  }
  fadeOut() {
    let canvasElements = [];
    this.interactives.collectCanvasElements(canvasElements);
    canvasElements.forEach((element) => {
      element.isHidden = true;
      element.invalidate();
    });
    this.isFadedOut = true;
    this.scene.icanvas.isDrawDebugText = false;
    // this.interactives.scene.icanvas.invaidate();
    this.scene.icanvas.try_invalidated_draw();
  }

  fadeIn(force_draw=true) {
    let canvasElements = [];
    this.interactives.collectCanvasElements(canvasElements);
    canvasElements.forEach((element) => {
      element.isHidden = false;
      element.invalidate();
    });
    this.isFadedOut = false;
    this.scene.icanvas.isDrawDebugText = true;
    this.scene.icanvas.invaidate();
    if(force_draw){
    this.scene.icanvas.try_invalidated_draw();

    }
  }

  onActivity(force_draw=true) {
    if (this.json.inactivityFadeoutSeconds) {
      if (this.isFadedOut) {
        this.fadeIn(force_draw);
        this.startFadeoutTimer();
      } else if (this.fadeTimout) {
        window.clearTimeout(this.fadeTimout);
        this.startFadeoutTimer();
      }
    }
  }
  startFadeoutTimer() {
    this.fadeTimout = window.setTimeout(() => {
      this.fadeTimout = undefined;
      this.fadeOut();
    }, this.json.inactivityFadeoutSeconds * 1000);
  }
  removeTemporary() {
    this.interactives.removeTemporary();
  }
  start() {
    this.interactives.start();

    if (this.json.inactivityFadeoutSeconds) {
      this.startFadeoutTimer();
    }
    this.isStarted = true;
  }

  // start_loaded() {
  //   this.interactives.start_loaded();

  // }

  stop() {
    this.interactives.stop();

    if (this.fadeTimout) {
      window.clearTimeout(this.fadeTimout);
    }
  }

  drawFrame(icanvas) {
    if (this.isFadedOut) {
      return;
    }

    this.interactives.drawFrame(icanvas);
  }
  mousedown(ievent) {
    this.interactives.mousedown(ievent);
    // this.onActivity();
  }
  mouseup(ievent) {
    this.interactives.mouseup(ievent);
    // this.onActivity();
  }
  mousemove(ievent) {
    this.interactives.mousemove(ievent);
    // if (this.interactives.isIEventOnInteractiveArea(ievent)) {

    // } 
    this.onActivity();
  }
  keydown(ievent) {
    this.interactives.keydown(ievent);
     this.onActivity();
  }
  keyup(ievent) {
    this.interactives.keyup(ievent);
     this.onActivity();
  }
  onTouchTap(ievent) {
    this.interactives.onTouchTap(ievent);
     this.onActivity();
  }
  onTouchPan(ievent) {
    this.interactives.onTouchPan(ievent);
     this.onActivity();
  }
  onTouchSwipe(ievent) {
    this.interactives.onTouchSwipe(ievent);
     this.onActivity();
  }
  onTouchDistance(ievent) {
    this.interactives.onTouchDistance(ievent);
     this.onActivity();
  }
  onTouchRotate(ievent) {
    this.interactives.onTouchRotate(ievent);
     this.onActivity();
  }
  onTouchGesture(ievent) {
    this.interactives.onTouchGesture(ievent);
     this.onActivity();
  }
  activate(ievent) {
    this.interactives.activate(ievent);
  }
  file_dropped(ievent) {
    this.interactives.file_dropped(ievent);
  }
  drag_file(ievent) {
    this.interactives.drag_file(ievent);
  }

  // addInteractive(name, json, isTemporary = false) {
  //   var i = this.interactives.addInteractive(name, json, isTemporary);

  //   return i;
  // }
  // removeInteractive(name) {
  //   this.interactives.removeInteractive(name);
  // }

  findInteractiveByName(name, isTemporary) {
    return this.interactives.findInteractiveByName(name, isTemporary);
  }
}
