import { PointWithMediaGeometry } from './PointWithMediaGeometry';
import { RectangleGeometry } from './RectangleGeometry';

export class GeometryElements {
    static createGeometryFromObjectWithJson(obj) {
        if (RectangleGeometry.getJsonEntry(obj.json)) {
            return new RectangleGeometry(obj);
        }
        if (PointWithMediaGeometry.getJsonEntry(obj.json)) {
            return new PointWithMediaGeometry(obj);
        }
        return undefined;
    } 
    
    static createRectangleGeometryFromJson(json) {
        var geometry_json = {};
        geometry_json["geometry.rectangle"]=json;
        var obj={json:geometry_json};
        return GeometryElements.createGeometryFromObjectWithJson(obj);       
    }
    static createPointMedediaRectangleGeometryFromJson(json) {
        var geometry_json = {};
        geometry_json["geometry.point"]=json;
        var obj={json:geometry_json};
        return GeometryElements.createGeometryFromObjectWithJson(obj);       
    }
}
