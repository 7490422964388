import { Action } from "../actions/action";

export class OpenAreaSizedModuleAction extends Action {
  value;
  value_context;
  constructor(verb, name, json) {
    super(verb, name);
    this.value = json["value"];
    this.value_context = json["value_context"];
  }

  static openModule(interactive,action,area_rect ){
    var module= interactive.scene.icanvas.externalModules.openModule(action.value, false,area_rect);
    module.data_context = action.value_context;
    var account_rewards = interactive.account.addTokenRewardSource(module.name);
    module.addActivationRewards(account_rewards);

  }
  run(interactive) {

    var area_rect = interactive.getScreenSpaceAreaRect();
    OpenAreaSizedModuleAction.openModule(interactive,this,area_rect);
  }
}

